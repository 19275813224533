import React, { useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { DialogClose } from "@radix-ui/react-dialog";
import { pencilSvg } from "../../svgs/svgs";
import { useDispatch, useSelector } from "react-redux";
import { removeUserById, updateUser } from "../../redux/actions/userAction";

function ShowMember({ imageUrl, name, role, id, userId }) {
  const dispatch = useDispatch();
  const initialRole = role; // Get the initial role from props
  let [user, setUser] = useState({ role: initialRole });
  const User = useSelector((state) => state.userReducer.user);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (value) => {
    user = { ...user, role: value }
    setUser({ ...user });
    dispatch(updateUser(id, user, userId));
  };

  const handleDelete = (id) => {
    dispatch(removeUserById(id, User?.teamId));
  }

  return (
    <div>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <div onClick={() => setIsOpen(true)} className="active:bg-blue-100 p-2 rounded-full">
          {pencilSvg}
        </div>
        <DrawerContent className=" bg-[#ECEDF9]">
          <DrawerHeader>
            <DrawerTitle></DrawerTitle>
            <DrawerDescription className="w-full mt-3 text-start">
              <div className=" flex justify-start px-3 items-center gap-x-5">
                <div>
                  <img
                    src={imageUrl}
                    className="h-[100px] w-[100px] object-cover"
                    style={{ borderRadius: "50%" }}
                    alt=""
                  />
                </div>
                <div className=" flex flex-col">
                  <div className=" text-2xl font-semibold">{name}</div>
                  <div className=" text-[14px]">Joined team on 26/12/2023</div>
                </div>
              </div>
              <div className=" border-b border-[#e7eoec] my-3" />
              <form>
                <div className=" flex justify-between items-center my-[30px] px-3">
                  <div className=" text-[18px] font-normal">Change role</div>
                  <Select
                    value={user.role}
                    onValueChange={handleChange}
                  >
                    <SelectTrigger
                      className="w-auto bg-[#5A72B5] text-white text-[14px] py-[4px] px-[10px]"
                      style={{ borderRadius: "5px" }}
                    >
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent
                      className=" "
                      style={{ borderRadius: "5px" }}
                    >
                      <SelectItem className="select" value="office">
                        Office
                      </SelectItem>
                      <SelectItem className="select" value="sitemanager">
                        Sitemanager
                      </SelectItem>
                      <SelectItem className="select" value="admin">
                        Admin
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className=" px-2 mt-3">
                  <Dialog>
                    <DialogTrigger className="w-full">
                      <Button
                        type={"button"}
                        className="mb-3 bg-transparent w-full h-[50px] flex items-center px-[16px] py-[24px] gap-x-3"
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #B3261E",
                        }}
                      >
                        <div className=" text-[#B3261E] text-[18px] font-[500]">Remove user</div>
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      className=" text-center w-[95%]"
                      style={{ borderRadius: "5px" }}
                    >
                      <div className=" text-xl font-semibold">Are you sure</div>
                      <div>You want to kick this member</div>
                      <div className=" flex justify-between items-center">
                        <DialogClose>
                          <Button
                            variant="primary"
                            className=" bg-indigo-500 text-white font-semibold"
                            style={{ borderRadius: "5px" }}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose>
                          <Button
                            onClick={() => handleDelete(id)}
                            variant="primary"
                            className=" bg-red-500 text-white font-semibold"
                            style={{ borderRadius: "5px" }}
                          >
                            Kick
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </form>
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default ShowMember;
import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GETINQUIRY, GETINQUIRYBYID } from "../types/types";
import toast from "react-hot-toast";

export const getInquiry = (teamId) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/inquiry/${teamId}`)
      .then((res) => {
        dispatch({ type: GETINQUIRY, data: res.data });
      })
      .catch((err) => {});
  };
};

export const addInquiry = (values, teamId) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/inquiry`, values)
      .then((res) => {
        dispatch(getInquiry(teamId));
        toast.success("Inquiry added");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
};

export const getInquiryById = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/inquiry/get/${id}`)
      .then((res) => {
        dispatch({ type: GETINQUIRYBYID, data: res.data });
      })
      .catch((err) => {});
  };
};

export const deleteInquiry = (id, teamId) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl}/api/inquiry/${id}`)
      .then((res) => {
        dispatch(getInquiry(teamId));
        toast.success("Inquiry deleted");
      })
      .catch((err) => {});
  };
};

export const editInquiry = (id, values, teamId) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/inquiry/${id}`, values)
      .then((res) => {
        dispatch(getInquiry(teamId));
        dispatch(getInquiryById(id));
        toast.success("Inquiry edited");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
};

import { GETINQUIRY, GETINQUIRYBYID } from "../types/types"

const defaultState = {
    inquiry: [],
    inquiryObj: {},
}

export const inquiryReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GETINQUIRY:
            return {
                ...state, inquiry: action.data
            }
        case GETINQUIRYBYID:
            return {
                ...state, inquiryObj: action.data
            }
        default:
            return {
                ...state
            }
    }
}
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useSelector } from "react-redux";
import { soldSvg, unsoldSvg} from "../svgs/svgs"

const AnalyticsPage = ({ setisLoading }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const User = useSelector((state) => state.userReducer.user);
    const [flats, setFlats] = useState({});

    useEffect(() => {
        setisLoading(true);
        axios.get(`${baseUrl}/api/flat/getprojectwiseflats/${User?.teamId}`)
        .then((res) => setFlats(res.data))
        .catch((err) => { })
        .finally(() => setisLoading(false));
    }, [User?.teamId]);

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[14vh] flex flex-col">
                <div className=" flex px-3 py-3 justify-start gap-x-3 items-centerh-[8%]">
                    <div className="flex rounded-full justify-center pt-[2px]">
                        <BiArrowBack
                            onClick={() => {
                                setTimeout(() => {
                                    navigate("/workspace");
                                }, 0);
                            }}
                            size={24}
                        />
                    </div>
                    <div className=" text-xl font-semibold cursor-pointer">Booking</div>
                </div>
                <div className="h-[90%]">
                    <div className="flex justify-between items-center h-full">
                        <div onClick={() => navigate("/booking")} className={`flex border-b border-[#e7eoec] justify-center items-center w-full h-full`}>Projects</div>
                        <div className={`flex ${pathname === "/analytics" ? 'border-b-2 border-[#6750A4]' : 'border-b border-[#e7eoec]'} justify-center items-center w-full h-full`}>Analytics</div>
                    </div>
                </div>
            </div>
            <div className="h-[86vh] overflow-y-auto">
                <div className="border-b border-[#e7eoec]">
                    <div className="px-3 py-3">
                        <div className="text-xl font-medium">Sales Breakdown</div>
                        <div className="flex justify-between gap-x-3 mt-2 w-full">
                            <div className="w-full flex py-2 px-2 border rounded-[16px] flex-col">
                                <div className="text-[16px]">Total Prop.</div>
                                <div className="text-xl font-medium">{flats?.totalFlats}</div>
                            </div>
                            <div className="w-full flex py-2 px-2 border rounded-[16px] flex-col">
                                <div className="text-[16px]">Sold</div>
                                <div className="text-xl font-medium text-[#4CAF50]">
                                    {flats?.allSold}
                                </div>
                            </div>
                            <div className="w-full flex py-2 px-2 border rounded-[16px] flex-col">
                                <div className="text-[16px]">Unsold</div>
                                <div className="text-xl font-medium text-[#F33]">
                                    {flats?.allUnsold}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-3 py-3">
                    <div className="text-xl font-medium">Sales Breakdown Project Wise</div>
                    <div className="flex my-2 justify-start items-center gap-x-3">
                        <div className="flex gap-x-2 items-center">
                            {soldSvg} Sold
                        </div>
                        <div className="flex gap-x-2 items-center">
                            {unsoldSvg} Unsold
                        </div>
                    </div>
                    <div className="mt-4">
                        {flats?.result?.map((val, index) => (
                            <div key={index} className="border-b border-[#E6E7F4] py-3">
                                <div className=" font-semibold text-[16px]">{val?.projectName}</div>
                                <div className="w-full h-[16px] my-2 flex gap-0">
                                    <div style={{width: val?.soldPercentage === "NaN%"? "0%" :val?.soldPercentage}} className={` bg-[#4CAF50]`}></div>
                                    <div style={{width: val?.unsoldPercentage === "NaN%"? "100%" :val?.unsoldPercentage}} className={` bg-[#FF3333]`}></div>
                                </div>
                                <div>
                                    <div className="flex w-full">
                                        <div className="w-[65%]">Sold</div>
                                        <div className="w-[15%]">{val?.sold}</div>
                                        <div className="w-[20%]">{val?.soldPercentage === "NaN%"? "0.00%" :val?.soldPercentage}</div>
                                    </div>
                                    <div className="flex w-full">
                                        <div className="w-[65%]">Unsold</div>
                                        <div className="w-[15%]">{val?.unSold}</div>
                                        <div className="w-[20%]">{val?.unsoldPercentage === "NaN%"? "0.00%" :val?.unsoldPercentage}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsPage;
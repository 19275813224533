import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GETANNOUNCEMENT } from "../types/types";

export const getAnnouncementByTeamId = (teamId) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/announcement/${teamId}`)
      .then((res) => {
        dispatch({ type: GETANNOUNCEMENT, data: res.data });
      })
      .catch((err) => {});
  };
};

export const addAnnouncement = (values) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/announcement`, values)
      .then((res) => {
        dispatch(getAnnouncementByTeamId(values.teamId));
      })
      .catch((err) => {});
  };
};

import { BiArrowBack } from "react-icons/bi";
import { deleteSvg } from "../svgs/svgs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useDispatch, useSelector } from "react-redux";
import { deleteFlat, updateFlatById } from "../redux/actions/flatAction";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { Settings } from "lucide-react";

const EditFlatPage = ({ setisLoading }) => {
    const { id, projectId } = useParams();
    const navigate = useNavigate();
    const bhk = ["1", "2", "3", "4", "5", "none"];
    let [flat, setFlat] = useState({
    });
    let [oldFlat, setOldFlat] = useState({
    });
    const [isBack, setIsBack] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const user = useSelector((state) => state.userReducer.user);
    const [role, setRole] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');
    const [projectName, setProjectName] = useState(null);
    const [status, setStatus] = useState("default");

    useEffect(() => {
        axios.get(`${baseUrl}/api/project/${projectId}`)
            .then((res) => setProjectName(res.data.projectName))
            .catch((err) => { })
    }, [projectId]);

    useEffect(() => {
        if (user?.role === "sitemanager") {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [user]);

    useEffect(() => {
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => {
                setFlat(res.data);
                setOldFlat(res.data);
                res.data.status && setStatus(res.data.status);
            })
            .catch((err) => { })
    }, [id]);

    const handleChange = (e) => {
        setIsBack(true);
        const { name, value } = e.target;
        flat = { ...flat, [name]: value };
        setFlat({ ...flat });
    }

    const navigateToBack = () => {
        navigate(`/flat-details/${id}/${projectId}?redirect=${redirect}`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (oldFlat?.customerName === "" && flat?.customerName !== "") {
            dispatch(updateFlatById(id, flat, projectId, setisLoading, navigateToBack, user?.userName, user?.imageUrl, `${`Changed ${flat?.series} ${projectName} to Sold`}`, `updated ${flat?.series} ${projectName}`, `/flat-details/${id}/${projectId}`));
        } else {
            dispatch(updateFlatById(id, flat, projectId, setisLoading, navigateToBack));
        }
    }


    const handleDelete = () => {
        dispatch(deleteFlat(id, projectId));
        navigate(`/project/${projectId}`);
    }

    const handleBack = () => {
        if (isBack) {
            setIsAlert(true);
        } else {
            navigate(`/flat-details/${id}/${projectId}/?redirect=${redirect}`);
        }
    }

    const handleStatusClick = (status) => {
        setStatus(status);
        flat = { ...flat, status: status };
        setFlat({ ...flat });
    }

    return (
        <div className="h-[100vh]">
            {/* Unsaved changes dialog */}
            <Dialog open={isAlert}>
                <DialogContent className={`w-[95vw] rounded-[8px]`}>
                    <DialogTitle>You want to save changes</DialogTitle>
                    <Button
                        variant="primary"
                        className="plus text-white font-semibold rounded-[5px]"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        variant="primary"
                        className=" bg-red-500 text-white font-semibold rounded-[5px]"
                        onClick={navigateToBack}
                    >
                        Discard
                    </Button>
                </DialogContent>
            </Dialog>

            <div className="h-[8vh] flex justify-between items-center ps-1 pe-3">
                <div className="flex justify-start items-center gap-x-1">
                    <div>
                        <div className="flex p-2 rounded-full justify-center items-center">
                            <BiArrowBack onClick={handleBack} size={24} />
                        </div>
                    </div>
                    <div className="text-xl font-semibold cursor-pointer">Edit</div>
                </div>
                <div className="flex gap-x-3">
                    <div>
                        <Dialog>
                            <DialogTrigger>
                                {deleteSvg}
                            </DialogTrigger>
                            <DialogContent
                                className=" text-center w-[95%]"
                                style={{ borderRadius: "5px" }}
                            >
                                <div className=" text-xl font-semibold">Are you sure</div>
                                <div>You want to delete this task</div>
                                <div className=" flex justify-between items-center">
                                    <DialogClose>
                                        <Button
                                            variant="primary"
                                            className=" bg-indigo-500 text-white font-semibold"
                                            style={{ borderRadius: "5px" }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogClose>
                                    <DialogClose>
                                        <Button
                                            onClick={() => handleDelete(id)}
                                            variant="primary"
                                            className=" bg-red-500 text-white font-semibold"
                                            style={{ borderRadius: "5px" }}
                                        >
                                            Delete
                                        </Button>
                                    </DialogClose>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="h-[92vh] px-3 py-3 overflow-auto">
                    <div className="flex gap-x-3 mb-3">
                        <div onClick={() => handleStatusClick("red")} className={`p-2 ${status === "red" && "bg-blue-300"} rounded-[5px]`}>
                            <div className="h-[20px] w-[20px] bg-[#f33] rounded-[5px]" />
                        </div>
                        <div onClick={() => handleStatusClick("yellow")} className={`p-2 ${status === "yellow" && "bg-blue-300"} rounded-[5px]`}>
                            <div className="h-[20px] w-[20px] bg-yellow-300 rounded-[5px]" />
                        </div>
                        <div onClick={() => handleStatusClick("default")} className={`p-2 ${status === "default" && "bg-blue-300"} rounded-[5px]`}>
                            <div className="h-[20px] w-[20px] sc rounded-[5px]" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div>Number</div>
                        <div className="col">
                            <input
                                type="text"
                                className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                                placeholder="101"
                                name="series"
                                value={flat?.series}
                                onChange={(e) => {
                                    flat = { ...flat, series: e.target.value };
                                    setFlat({ ...flat });
                                }}
                            />
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Name</label>
                        <input
                            type="text"
                            className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                            placeholder="Jaydeep R"
                            name="customerName"
                            value={flat?.customerName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Mobile No.</label>
                        <input
                            type="number"
                            className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                            placeholder="9999999999"
                            name="mobileNumber"
                            value={flat?.mobileNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Alternate Mobile No.</label>
                        <input
                            type="number"
                            className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                            placeholder="9999999999"
                            name="alternateMobileNumber"
                            value={flat?.alternateMobileNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Broker/Dalal Name</label>
                        <input
                            type="text"
                            className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                            placeholder="Broker Name"
                            name="brokerName"
                            value={flat?.brokerName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Broker/Dalal Number</label>
                        <input
                            type="number"
                            className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                            placeholder="9999999999"
                            name="brokerNumber"
                            value={flat?.brokerNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <label className="mb-1">Square feet</label>
                            <input
                                type="number"
                                className="w-full rounded-[8px] sc px-3 outline-none h-[50px]"
                                placeholder="4500"
                                name="squareFeet"
                                step={"any"}
                                value={flat?.squareFeet}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-4">
                            <label className="mb-1">BHK*</label>
                            <Select value={flat?.bhkSize} onValueChange={(value) => {
                                flat = { ...flat, bhkSize: value };
                                setFlat({ ...flat });
                            }}>
                                <SelectTrigger className="sc px-[16px] h-[50px] shadow-none border-0 rounded-[8px]">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="" style={{ borderRadius: "5px" }}>
                                    {bhk.map((bhk, index) => (
                                        <SelectItem key={index} className="select" value={bhk}>
                                            {bhk}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className="flex gap-x-2 justify-between mt-3">
                        <button type="submit" className="plus h-[50px] w-full text-white rounded-[8px]">
                            Save
                        </button>
                        <button onClick={() => navigate(`/flat-details/${id}/${projectId}?redirect=${redirect}`)} type="button" className="bg-[#5A5F6E] h-[50px] w-full text-white rounded-[8px]">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EditFlatPage;
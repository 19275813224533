import React, { useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Plus } from "lucide-react";
import { Button } from "../ui/button";
import { DialogClose } from "@radix-ui/react-dialog";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "../../redux/actions/projectAction";

function AddProjectDialog({ setisLoading }) {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.userReducer.user);
  let [project, setProject] = useState({
    projectName: "",
    imageUrl: "/images/logo.png",
    teamId: "",
    createdBy: "",
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    project = { ...project, [name]: value };
    setProject({ ...project });
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    project = { ...project, teamId: User.teamId, createdBy: User.userName };
    setProject({ ...project });
    dispatch(addProject(project, project.teamId, setisLoading));
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <button
            className="h-[62px] w-[62px] flex justify-center
             items-center plus absolute active:scale-[0.9] transition-all"
            style={{ borderRadius: "16px" }}
          >
            <Plus size={34} color="#fff" />
          </button>
        </DialogTrigger>
        <DialogContent
          className=" top-56 w-[95vw]"
          style={{ borderRadius: "10px" }}
        >
          <form onSubmit={handleSubmit}>
            <label htmlFor="">Project name*</label>
            <input
              type="text"
              name="projectName"
              className="my-2 ps-[16px] h-[50px] w-full sc outline-none"
              style={{ borderRadius: "8px" }}
              placeholder="Opera Sky"
              onChange={handleChange}
            />

            {/* Add Later */}
            {/* <label className="mt-2" htmlFor="logo">Logo*</label>
            <label
              htmlFor="logo"
              className="my-2 p-2 h-[100px] flex justify-center items-center w-full sc outline-none"
              style={{ borderRadius: "8px" }}
            >
              <div className=" flex flex-col text-[#49454F] h-full justify-center items-center w-full">
                <MdPhotoSizeSelectActual />
                <div>Click here to upload</div>
                <div>(1:1 image recomended)</div>
              </div>
            </label> */}
            {/* <input
              id="logo"
              type="file"
              className="d-none "
              style={{ borderRadius: "8px" }}
              placeholder="Ex. Project Name"
            /> */}

            <DialogClose className="w-full">
              <Button
                type="submit"
                variant={"primary"}
                className="flex active:bg-opacity-70 gap-x-2 text-[18px] h-[50px] py-4 mt-4 text-white justify-center items-center plus w-full font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Create
              </Button>
            </DialogClose>
            <DialogClose className="w-full">
              <Button
                variant="primary"
                type="button"
                className="mt-3 border-[1px] discard h-[48px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Discard
              </Button>
            </DialogClose>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddProjectDialog;

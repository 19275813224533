import { HOC } from "../components/HOC/HOC";
import ActivityPage from "../pages/activity-page";
import AddAccessPage from "../pages/add-access-page";
import AddRent from "../pages/add-rent-page";
import AnalyticsPage from "../pages/analytics-page";
import AnnouncementPage from "../pages/announcement-page";
import BookingPage from "../pages/booking-page";
import CreateWorkspace from "../pages/create-workspace";
import EditFlatPage from "../pages/edit-flat-page";
import FilterPage from "../pages/filter-page";
import FlatDetails from "../pages/flat-details-page";
import HomePage from "../pages/home-page";
import InquiryDetails from "../pages/inquiry-details";
import InquiryRecord from "../pages/inquiry-record";
import ManageAccess from "../pages/manage-access-page";
import MaterialPage from "../pages/material-page";
import PaymentDetails from "../pages/payment-detail";
import PendingTask from "../pages/pending-task";
import ProfilePage from "../pages/profile-page";
import ProjectPage from "../pages/project-page";
import RentDetailsPage from "../pages/rent-details-page";
import RentPage from "../pages/rent-page";
import RentPayment from "../pages/rents-payment-page";
import SigninPage from "../pages/signin-page";
import TaskDetailsPage from "../pages/task-details-page";
import TaskTeamList from "../pages/task-team-list";
import TeamPage from "../pages/team-page";
import TodayPayment from "../pages/today-payment-page";
import UserTaskPage from "../pages/user-task-page";
import WingFlatList from "../pages/wing-flat-list";
import WingFlat from "../pages/wing-flat-page";
import WorkspacePage from "../pages/workspace-page";

export const routeArr = [
  {
    href: "/signin",
    page: SigninPage,
  },
  {
    href: "/",
    page: HomePage,
  },
  {
    href: "/createworkspace",
    page: CreateWorkspace,
    isPrivate: true,
  },
  {
    href: "/workspace",
    page: WorkspacePage,
    isPrivate: true,
  },
  {
    href: "/taskdetails/:id/:email/:name/:series",
    page: TaskDetailsPage,
    isPrivate: true,
  },
  {
    href: "/pR7sKjFvL2yXu6iA4qWb",
    page: HOC,
    isPrivate: true,
  },
  {
    href: "/team",
    page: TeamPage,
    isPrivate: true,
  },
  {
    href: "/material",
    page: MaterialPage,
    isPrivate: true,
  },
  {
    href: "/booking",
    page: BookingPage,
    isPrivate: true,
  },
  {
    href: "/project/:id",
    page: ProjectPage,
    isPrivate: true,
  },
  {
    href: "/wingproject/:id",
    page: WingFlat,
    isPrivate: true,
  },
  {
    href: "/wingprojectlist/:key/:id",
    page: WingFlatList,
    isPrivate: true,
  },
  {
    href: "/filter/:id",
    page: FilterPage,
    isPrivate: true,
  },
  {
    href: "/profile",
    page: ProfilePage,
    isPrivate: true,
  },
  {
    href: "/inquiry-record",
    page: InquiryRecord,
    isPrivate: true,
  },
  {
    href: "/inquiry-details/:series/:id",
    page: InquiryDetails,
    isPrivate: true,
  },
  {
    href: "/announcement",
    page: AnnouncementPage,
    isPrivate: true,
  },
  {
    href: "/flat-details/:id/:projectId",
    page: FlatDetails,
    isPrivate: true,
  },
  {
    href: "/flat-edit/:id/:projectId",
    page: EditFlatPage,
    isPrivate: true,
  },
  {
    href: "/payment-detail/:id/:projectId",
    page: PaymentDetails,
    isPrivate: true,
  },
  {
    href: "/today-payment",
    page: TodayPayment,
    isPrivate: true,
  },
  {
    href: "/analytics",
    page: AnalyticsPage,
    isPrivate: true,
  },
  {
    href: "/activity",
    page: ActivityPage,
    isPrivate: true,
  },
  {
    href: "/rent/:id",
    page: RentPage,
    isPrivate: true,
  },
  {
    href: "/addrent/:projectId",
    page: AddRent,
    isPrivate: true,
  },
  {
    href: "/rent-details/:id/:series/:projectId",
    page: RentDetailsPage,
    isPrivate: true,
  },
  {
    href: "/rent-payment/:id/:series",
    page: RentPayment,
    isPrivate: true,
  },
  {
    href: "/taskteam",
    page: TaskTeamList,
    isPrivate: true,
  },
  {
    href: "/usertask/:email/:name",
    page: UserTaskPage,
    isPrivate: true,
  },
  {
    href: "/manageaccess/:id",
    page: ManageAccess,
    isPrivate: true,
  },
  {
    href: "/addaccess/:id",
    page: AddAccessPage,
    isPrivate: true,
  },
];
import React, { useEffect, useRef, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getTaskById, updateTask } from '../redux/actions/taskAction';
import { completeBlackSvg, completeWhiteSvg, notSeenSvg, seenSvg } from '../svgs/svgs';
import DeleteTask from '../components/customUi/delete-task';
import { SendHorizontal } from 'lucide-react';
import axios from 'axios';
import { baseUrl } from '../lib/base-url';
import { DialogClose, DialogTrigger, Dialog, DialogContent, DialogTitle } from '../components/ui/dialog';
import { Button } from '../components/ui/button';

function TaskDetailsPage({ setisLoading }) {
  const navigate = useNavigate();
  const { id, email, name, series } = useParams();
  const dispatch = useDispatch();
  const task = useSelector((state) => state.taskReducer.task);
  const user = useSelector((state) => state.userReducer.user);
  const [seenByName, setSeenByName] = useState("");
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [isSelfTask, setIsSelfTask] = useState(true);

  useEffect(() => {
    if (task.assignTo !== task.createdBy) {
      setIsSelfTask(false);
    }
  }, [task.assignTo, task.createdBy]);

  useEffect(() => {
    if (task.assignTo !== undefined) {
      axios.get(`${baseUrl}/api/user/email/${task?.assignTo}`)
        .then((res) => {
          setSeenByName(res.data.userName)
        })
        .catch((err) => { });
    }
  }, [task]);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [task.chat]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      message: message,
      userImage: user.imageUrl,
      userName: user.userName,
      time: Date.now(),
      path: `${email}/${name}`,
    }

    axios.get(`${baseUrl}/api/task/${id}`)
      .then((res) => {
        let chatArray = [];
        if (res.data.chat) {
          chatArray = [...res.data.chat]; // Copying existing chat array
        }
        chatArray.push(obj); // Appending new object to chat array
        dispatch(updateTask(id, user.userName, user.teamId, {
          chat: chatArray,
        },
          setisLoading
        ));
        setMessage("");
      })
      .catch((err) => {
        // handle Error
      })
    // Assuming that the initial chat array is empty if not present

  }

  const handleUpdate = (id, teamId) => {
    if (task.assignTo === user?.email) {
      dispatch(updateTask(id, user.userName, teamId, { isCompleted: task.isCompleted ? false : true }, setisLoading))
    }
  }

  const handleCancel = () => {
    dispatch(updateTask(id, user.userName, user.teamId, { dueDate: 0 }, setisLoading))
  }

  useEffect(() => {
    // Function to fetch task by ID
    const fetchTask = () => {
      dispatch(getTaskById(id));
    };

    // Call the function initially when the component mounts
    fetchTask();

    // Set interval to fetch task every 30 seconds
    const interval = setInterval(fetchTask, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, id]);

  useEffect(() => {
    if (task.assignTo === user?.email) {
      if (task?.isSeen !== true) {
        dispatch(updateTask(id, user?.userName, user?.teamId, { isSeen: true, seenTime: Date.now() }, setisLoading));
      }
    }
  }, [user.email, user?.userName, user.teamId, dispatch, id, task?.isSeen, task.assignTo, setisLoading]);

  function convertTimestampToFormattedDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
  }

  function formatTimestamp(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Get the hours, minutes, seconds, month, day, and year
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const month = date.getMonth(); // Months are zero-indexed (January = 0)
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the hours using AM/PM notation
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour clock format

    // Format the month name
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[month];

    // Return the formatted string
    return `${hours}:${minutes} ${amPm}, ${monthName} ${day}, ${year}`;
  }

  function formatTimestamp2(timestamp) {
    const date = new Date(timestamp);
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <div className='h-screen flex flex-col'>
      <div className="h-[8%] flex justify-between items-center px-3">
        <div className="flex justify-between w-full items-center gap-x-3">
          <div className='flex gap-x-3'>
            <div onClick={() => navigate(`/usertask/${email}/${name}`)} className="flex rounded-full justify-center items-center">
              <BiArrowBack size={24} />
            </div>
            <div className="text-xl font-medium cursor-pointer">{series}.</div>
          </div>
          <div>
            {(task?.isCompleted && task.assignTo !== user?.email) && (
              <div className='flex gap-x-3 items-center text-white bg-[#4CAF50] border border-[#4CAF50] py-2 px-3 rounded-[5px]'>{completeWhiteSvg} Complete</div>
            )}
            {(task.assignTo === user?.email) && (
              <>
                {task.isCompleted ? (
                  <div className='flex gap-x-3 items-center text-white bg-[#4CAF50] border border-[#4CAF50] py-2 px-3 rounded-[5px]'>{completeWhiteSvg} Complete</div>
                ) : (
                  <Dialog>
                    <DialogTrigger>
                      <div className='flex gap-x-3 items-center border border-black py-2 px-3 rounded-[5px]'>
                        {completeBlackSvg} Complete
                      </div>
                    </DialogTrigger>
                    <DialogContent className={`w-[95vw] rounded-[8px]`}>
                      <DialogTitle>You want complete this task</DialogTitle>
                      <DialogClose className='w-full'>
                        <Button
                          variant="primary"
                          className="plus w-full text-white font-semibold rounded-[5px]"
                          onClick={() => handleUpdate(task._id, user.teamId)}
                        >
                          Complete
                        </Button>
                      </DialogClose>
                      <DialogClose className='w-full'>
                        <Button
                          variant="primary"
                          className=" bg-red-500 w-full text-white font-semibold rounded-[5px]"
                        >
                          Cancel
                        </Button>
                      </DialogClose>
                    </DialogContent>
                  </Dialog>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='flex-1 flex flex-col overflow-hidden'>
        <div className={`border-b border-[#DEE2F4] px-3 pb-3 overflow-hidden`}>
          <div className='flex py-2 justify-between gap-x-2 items-center'>
            <div className='flex justify-center items-center gap-x-3'>
              <div className='text-2xl font-medium'>{task.taskTitle}</div>
            </div>
          </div>
          <div className={`text-[16px] py-1 flex justify-between items-center text-[#3E4251] font-normal`}>
            <div>Created on {convertTimestampToFormattedDate(task.createdAt)}</div>
            <div className={`px-1 ${user.role !== "admin" && "hidden"}`}><DeleteTask setisLoading={setisLoading} email={email} name={name} teamId={user.teamId} id={id} /></div>
          </div>
          <div>
            {user?.role === "admin" && (
              <div className='flex justify-between mt-2'>
                <div>Task info</div>
                <div className={`flex gap-x-3 ${task?.isSeen ? "text-[#003C9A]" : "text-[#3E4251]"}`}>{task?.isSeen ? "Seen" : "Not seen"} {task?.isSeen ? seenSvg : notSeenSvg}</div>
              </div>
            )}
          </div>
        </div>
        <div className='overflow-y-auto flex-1' ref={chatContainerRef}>
          <div className='px-3 pt-2 pb-12'>
            {task.chat?.map((chat, index) => (
              <MessageComp key={index} name={chat.userName} imageUrl={chat.userImage} message={chat.message} time={chat.time} />
            ))}
          </div>
        </div>
      </div>
      <div className={`w-full mb-3 px-3 bg-[#FAF8FF]`}>
        <form onSubmit={handleSubmit} className='flex items-center gap-x-2'>
          <input
            type="text"
            className='h-[50px] focus:outline-none px-3 rounded-full w-full'
            placeholder='Send Message'
            name='message'
            value={message}
            onChange={(e) => {
              const { value } = e.target;
              setMessage(value);
            }}
          />
          <button className='bg-[#0066FA] p-2 rounded-full' type='submit'><SendHorizontal color="white" /></button>
        </form>
      </div>
    </div>
  );
}

export default TaskDetailsPage;

const MessageComp = ({ name, imageUrl, message, time }) => {
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Add leading zeros if necessary
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes} ${period}`;
  }
  return (
    <div className='mt-3'>
      <div className='flex gap-x-3'>
        <img src={imageUrl} className='h-8 w-8 rounded-full' alt="user" />
        <div>
          <div className='flex justify-start flex-wrap items-center gap-x-2'>
            <div className='font-medium text-md'>{name}</div>
            <div className='text-[10px] text-[#424655]'>{formatDate(time)}</div>
          </div>
          <div className='text-md'>
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

import { GET_PROJECT_BYID, GET_PROJECT_BYTEAM } from "../types/types"

const defaultState = {
    projects: [],
    project: {},
}

export const projectReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_PROJECT_BYTEAM:
            return {
                ...state, projects: action.projects
            }
        case GET_PROJECT_BYID:
            return {
                ...state, project: action.project
            }
        default:
            return {
                ...state
            }
    }
}
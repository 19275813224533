import React from "react";
import { completeDeed, completeDeedContactSvg, contactSvg } from "../../svgs/svgs";
import { useNavigate } from "react-router-dom";

function ProjectListComp({
  series,
  squareFeet,
  name,
  bhk,
  contact,
  deed,
  flatId,
  id,
  redirect,
  status,
}) {
  const navigate = useNavigate();

  const handlePhoneIconClick = (e) => {
    e.stopPropagation();
    if (contact) {
      window.location.href = `tel:${contact}`;
    }
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case 'red':
        return 'bg-[#f33] text-white';
      case 'yellow':
        return 'bg-yellow-300';
      default:
        return name !== "" ? "bg-[#4CAF50] text-white" : "sc";
    }
  };

  return (
    <>
      <div className="w-full text-start" onClick={() => {
        navigate(`/flat-details/${flatId}/${id}?redirect=${redirect}`)
      }}>
        <div
          className={`${getStatusBgColor(status)} mb-2 p-[17px]`}
          style={{ borderRadius: "8px" }}
        >
          <div className={` flex justify-between flex-wrap items-center`}>
            <div className={`flex ${contact !== "" ? "w-[80%]" : "w-[100%]"} whitespace-nowrap justify-between gap-x-3 items-center`}>
              <div className=" text-lg font-medium">{series}</div>
              <div className=" text-xs">
                {bhk === "none" ? "" : bhk + "-BHK"}
              </div>
              {squareFeet !== null && (
                <div className="text-xs">
                  {squareFeet} sqft
                </div>
              )}
              {
                name !== "" && (
                  <div
                    className={`text-xs flex items-center gap-x-1 rounded-[2px] px-2 py-1 ${name === "" ? " hidden" : "opacity-100"} ${deed ? " bg-white" : "bg-[#6C757D] hidden"
                      } text-black`}
                  >
                    {completeDeed}{"Deed"}
                  </div>
                )
              }
            </div>
            {contact !== "" && (
              <div
                onClick={handlePhoneIconClick}
                className={`${name !== "" ? "bg-white" : "bg-black"} p-[8px]`}
                style={{ borderRadius: "50%" }}
              >
                {name !== "" ? completeDeedContactSvg : contactSvg}
              </div>
            )}
          </div>
          {name !== "" && <div className=" text-[18px] mt-2">{name}</div>}
        </div>
      </div>
    </>
  );
}

export default ProjectListComp;
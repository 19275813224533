import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GET_PROJECT_BYID, GET_PROJECT_BYTEAM } from "../types/types";
import toast from "react-hot-toast";

export const getProjectByTeamId = (teamId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/project/getProjectsByTeamId/${teamId}`)
      .then((res) => {
        dispatch({ type: GET_PROJECT_BYTEAM, projects: res.data });
        setisLoading(false);
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const getProjectById = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/project/${id}`)
      .then((res) => {
        dispatch({ type: GET_PROJECT_BYID, project: res.data });
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const addProject = (project, teamId, setisLoading) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/project`, project)
      .then((res) => {
        toast.success("Project added");
        dispatch(getProjectByTeamId(teamId, setisLoading));
      })
      .catch((err) => {
        toast.success("Something went wrong");
      });
    };
  };
  
  export const deleteProject = (id, teamId) => {
    return (dispatch) => {
      axios
      .delete(`${baseUrl}/api/project/${id}`)
      .then((res) => {
        toast.success("Project deleted");
        dispatch(getProjectByTeamId(teamId));
      })
      .catch(() => {
        toast.success("Something went wrong");
      });
  };
};

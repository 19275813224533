import axios from "axios";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../lib/base-url";
import { contactSvg, deleteSvg, paymentSvg, pencilSvg, whatsappSvg } from "../svgs/svgs";
import { Button } from "../components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../components/ui/dialog";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { editRent } from "../redux/actions/rentAction";

const RentDetailsPage = ({ setisLoading }) => {
    const navigate = useNavigate();
    const { id, series, projectId } = useParams();
    const [rent, setRent] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setisLoading(true);
        getRentFunction();
    }, [id]);

    const getRentFunction = () => {
        axios.get(`${baseUrl}/api/rent/get/${id}`)
            .then((res) => setRent(res.data))
            .catch((err) => { })
            .finally(() => setisLoading(false));
    }

    const handlePhoneIconClick = (number) => {
        if (number) {
            window.location.href = `tel:${number}`;
        }
    };

    const handleWhatsappIconClick = (number) => {
        if (number) {
            // Assuming contact is a valid WhatsApp number
            const whatsappLink = `https://wa.me/${number.replace(/\s/g, "")}`;
            window.location.href = whatsappLink;
        }
    };

    const handleDelete = (id) => {
        axios.delete(`${baseUrl}/api/rent/${id}`)
            .then((res) => {
                if (res.status === 200) toast.success("Deleted successfully");
                navigate(`/rent/${projectId}`);
            })
            .catch((err) => {
                toast.success("Something went wrong");
            });
    }

    function convertDate(dateStr) {
        var parts = dateStr.split('-');
        if (parts.length !== 3) {
            return "Invalid date format";
        }

        var yyyy = parts[0];
        var mm = parts[1];
        var dd = parts[2];

        return dd + '-' + mm + '-' + yyyy;
    }

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate(`/rent/${projectId}`)} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">{series}.</div>
                </div>
                <div className="flex items-center gap-x-8">
                    <div onClick={() => navigate(`/rent-payment/${id}/${series}`)}>{paymentSvg}</div>
                    <div onClick={() => navigate(`/addrent/${projectId}?id=${id}`)}>{pencilSvg}</div>
                    <div>
                        <Dialog>
                            <DialogTrigger className="py-2">
                                {deleteSvg}
                            </DialogTrigger>
                            <DialogContent
                                className=" text-center w-[95%] rounded-[5px]"
                            >
                                <div className=" text-xl font-semibold">Are you sure</div>
                                <div>You want to delete this</div>
                                <div className=" flex justify-between items-center">
                                    <DialogClose>
                                        <Button
                                            variant="primary"
                                            className=" bg-indigo-500 text-white font-semibold rounded-[5px]"
                                        >
                                            Cancel
                                        </Button>
                                    </DialogClose>
                                    <DialogClose>
                                        <Button
                                            onClick={() => handleDelete(id)}
                                            variant="primary"
                                            className=" bg-red-500 text-white font-semibold rounded-[5px]"
                                        >
                                            Delete
                                        </Button>
                                    </DialogClose>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
            <div className="h-[92%] p-3 overflow-auto">
                {rent.projectName && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Project Name</div>
                        <div className="text-[20px] font-medium capitalize">{rent?.projectName}</div>
                    </div>
                )}
                {rent.propertyNumber && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Property Number</div>
                        <div className="text-[20px] font-medium">{rent?.propertyNumber}</div>
                    </div>
                )}
                {rent.BhadutName && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Bhadut Name</div>
                        <div className="text-[20px] font-medium">{rent?.BhadutName}</div>
                    </div>
                )}
                {rent.rentAmount && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Rent</div>
                        <div className="text-[20px] font-medium">{rent?.rentAmount}/m</div>
                    </div>
                )}
                {rent.deposit && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Deposit</div>
                        <div className="text-[20px] font-medium">{rent?.deposit}</div>
                    </div>
                )}
                {rent.mobileNumber && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Mobile No.</div>
                        <div className="flex items-center justify-between text-[20px] font-medium">
                            <div>+91 {rent?.mobileNumber}</div>
                            <div className="flex items-center gap-x-3">
                                <div onClick={() => handleWhatsappIconClick(rent?.mobileNumber)}>{whatsappSvg}</div>
                                <div onClick={() => handlePhoneIconClick(rent?.mobileNumber)} className="p-[8px] bg-black rounded-full">{contactSvg}</div>
                            </div>
                        </div>
                    </div>
                )}
                {rent.rentStart && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Rent starting date</div>
                        <div className="text-[20px] font-medium">{convertDate(rent?.rentStart)}</div>
                    </div>
                )}
                {rent.depositeDate && (
                    <div className="border-b py-2 border-[#E1E2EE]">
                        <div className="mb-2 text-[18px] text-[#3E4251]">Rent deposite date</div>
                        <div className="text-[20px] font-medium">{convertDate(rent?.depositeDate)}</div>
                    </div>
                )}
                <div className="my-2">
                    <div className="mb-2 text-[18px] text-[#3E4251]">Rent Status</div>
                    <div className="p-2 flex justify-between gap-x-[16px] text-[18px] font-[500] bg-[#ECEDF9] rounded=[4px]">
                        <div
                            onClick={() => {
                                if (rent?.rentStatus === false) {
                                    dispatch(editRent(id, { rentStatus: true }, getRentFunction, setisLoading))
                                }
                            }}
                            className={`w-full py-[14px] text-center h-full ${rent?.rentStatus && "bg-[#4CAF50] text-white rounded-[4px]"}`}>Rented</div>
                        <div
                            onClick={() => {
                                if (rent?.rentStatus === true) {
                                    dispatch(editRent(id, { rentStatus: false }, getRentFunction, setisLoading))
                                }
                            }}
                            className={`w-full py-[14px] text-center ${!rent?.rentStatus && "bg-[#F33] text-white rounded-[4px]"}`}>Not rented</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RentDetailsPage;
import { X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { accessAllow, accessNotAllow } from "../svgs/svgs";
import { updateUserByEmail } from "../redux/actions/userAction";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useState } from "react";
import toast from "react-hot-toast";

const AddAccessPage = ({ setisLoading }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const team = useSelector((state) => state.userReducer.teamUser);
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    const updateUser = (user) => {
        setisLoading(true);
        let updatedBooking = "";

        if (!user.booking) {
            updatedBooking = id;
        } else {
            // Concatenate the id with a comma to the existing booking
            updatedBooking = user.booking + "," + id;
        }

        if (!user.booking.includes(id)) {
            axios.get(`${baseUrl}/api/user/${user.userId}`)
                .then((res) => {
                    setUser(res.data);
                    dispatch(updateUserByEmail(user.email, { booking: updatedBooking }, user.userId, "Access Granted", setisLoading));
                })
                .catch(() => {
                    // handle Error
                });
        }
    }

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center px-1 border-b border-[#e7eoec]">
                <div className=" flex py-2 px-3 justify-between items-center w-full">
                    <div className=" flex justify-start items-center gap-x-3">
                        <div className=" rounded-full flex justify-center items-center">
                            <X
                                onClick={() => {
                                    navigate(`/manageaccess/${id}`)
                                }} size={24} />
                        </div>
                        <div className=" text-xl font-semibold cursor-pointer">
                            Add people
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[92%] py-3 px-4 overflow-auto">
                {team.map((user, index) => (
                    <div className="flex justify-between items-center mb-[24px]" key={index}>
                        <div className="flex gap-x-3 items-center">
                            <img src={user.imageUrl} className="h-[28px] w-[28px] rounded-full" alt="" />
                            <div className="capitalize">
                                <div className="text-xl">{user.userName}</div>
                                <div className="text-[14px]">{user.role}</div>
                            </div>
                        </div>
                        <div onClick={() => updateUser(user)}>
                            {user.booking && (user.booking.includes(id) || user.booking.includes("all")) ? accessAllow : accessNotAllow}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AddAccessPage;
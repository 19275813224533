import React, { useEffect, useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ProjectListComp from "../components/customUi/project-list-comp";
import AddPlotDialog from "../components/customUi/add-plot-dialog";
import { useDispatch, useSelector } from "react-redux";
import { getFlatByProjectId } from "../redux/actions/flatAction";
import { getProjectById } from "../redux/actions/projectAction";
import { wingWiseSvg } from "../svgs/svgs";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../components/ui/dialog";
import { IoMdArrowDropdown } from "react-icons/io";
import { X } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

function ProjectPage({ setisLoading }) {
  const user = useSelector((state) => state.userReducer.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const flats = useSelector((state) => state.flatReducer.flats);
  const project = useSelector((state) => state.projectReducer.project);
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState(localStorage.getItem("sitenoteFilter") || "");
  const [filter2, setFilter2] = useState(localStorage.getItem("sitenoteFilter2") || "");
  const { pathname } = useLocation();
  const scrollRef = useRef(null);
  const team = useSelector((state) => state.userReducer.teamUser);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    // Filter users based on the condition
    if (team) {
      const filtered = team.filter(user => {
        if (user.booking === "all") {
          return true;
        } else {
          const ids = user.booking.split(',').map(item => item.trim());
          return ids.includes(id);
        }
      });
      setFilteredUsers(filtered);
    }
  }, [id, team]);

  useEffect(() => {
    setIsFilter(id === "65fd3d77656bc6f747437aff");
  }, [id]);

  useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);

  const fetchData = () => {
    dispatch(getFlatByProjectId(id, setisLoading));
  };

  useEffect(() => {
    fetchData();

    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(fetchDataInterval);
  }, [id, dispatch, setisLoading]);

  useEffect(() => {
    setRole(user?.role !== "sitemanager")
  }, [user?.role]);

  useEffect(() => {
    localStorage.setItem("sitenoteFilter", filter);
    localStorage.setItem("sitenoteFilter2", filter2);
  }, [filter, filter2]);

  // Save scroll position when leaving the page
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = localStorage.getItem("scrollPosition") || 0;
    }
  }, [pathname]);

  // Save scroll position when leaving the page
  const handleScroll = () => {
    localStorage.setItem("scrollPosition", scrollRef.current.scrollTop);
  };

  // Custom sorting function
  const customSort = (a, b) => {
    const seriesA = a.series.toUpperCase();
    const seriesB = b.series.toUpperCase();

    // Function to extract letters from series string
    const extractLetters = (str) => str.match(/[A-Za-z]+/) ? str.match(/[A-Za-z]+/)[0] : '';

    // Function to extract numbers from series string
    const extractNumber = (str) => str.match(/\d+/) ? parseInt(str.match(/\d+/)[0]) : Infinity;

    // Extract letters and numbers from series strings
    const lettersA = extractLetters(seriesA);
    const lettersB = extractLetters(seriesB);
    const numA = extractNumber(seriesA);
    const numB = extractNumber(seriesB);

    // If letters are different, sort by letters
    if (lettersA !== lettersB) {
      return lettersA.localeCompare(lettersB);
    }

    // If letters are the same, sort by numbers
    if (numA !== numB) {
      return numA - numB;
    }

    // If both letters and numbers are the same, no change in order
    return 0;
  };

  // Sort the flats array
  const sortedFlats = [...flats].sort(customSort);

  // Filter the flats based on selected filters
  const filteredFlats = sortedFlats.filter(flat => {
    if (filter === "Sold") {
      return flat.customerName !== "";
    } else if (filter === "Unsold") {
      return flat.customerName === "";
    } else {
      return true; // Show all if no filter is selected
    }
  }).filter(flat => {
    if (filter2 !== "") {
      if (filter2 === "Application" || filter2 === "Sanctioned" || filter2 === "Process") {
        // Compare flat?.loanStatus here
        return flat.loanStatus === filter2;
      } else {
        // For other filter2 values, just compare paymentType
        return flat.paymentType === filter2;
      }
    } else {
      return true; // Show all if no filter is selected
    }
  });

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  // Function to clear filters
  const clearFilters = () => {
    setFilter("");
    setFilter2("");
    localStorage.removeItem("sitenoteFilter");
    localStorage.removeItem("sitenoteFilter2");
  };

  return (
    <div className="h-[100vh]">
      <div className=" h-[8%] flex justify-between items-center px-1 border-b border-[#e7eoec]">
        <div className=" flex py-2 px-3 justify-between items-center w-full">
          <div className=" flex justify-start items-center gap-x-3">
            <div className=" rounded-full flex justify-center items-center">
              <BiArrowBack
                onClick={() => {
                  setTimeout(() => {
                    navigate("/booking")
                  }, 0);
                }} size={24} />
            </div>
            <div className=" text-xl font-semibold cursor-pointer">
              {project.projectName}
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            {user?.role === "admin" && (
              <div onClick={() => navigate(`/manageaccess/${id}`)} className='flex'>
                <img src={user?.imageUrl} className='h-[28px] z-30 w-[28px] rounded-full' alt="" />
                <img src={user?.imageUrl !== filteredUsers[0]?.imageUrl ? filteredUsers[0]?.imageUrl : filteredUsers[1]?.imageUrl} className='h-[28px] z-20 ml-[-9px] w-[28px] rounded-full' alt="" />
                {filteredUsers.length > 2 && (
                  <div className='h-[28px] text-white z-10 flex justify-center items-center ml-[-9px] w-[28px] rounded-full bg-[#003C9A] text-[14px]'>+{filteredUsers.length - 2}</div>
                )}
              </div>
            )}
            {!isFilter && (
              <div onClick={() => {
                navigate(`/wingproject/${project._id}`)
              }}>{wingWiseSvg}</div>
            )}
          </div>
        </div>
      </div>
      <div ref={scrollRef} onScroll={handleScroll} className="h-[92%] py-3 overflow-y-auto px-3">
        <div className="flex mb-3 items-center justify-start gap-x-2">
          <Dialog>
            <div className={`${filter !== "" && "bg-[#5A72B5] rounded-[5px] text-white border-[#5A72B5]"} flex items-center`}>
              <DialogTrigger>
                <div className={`px-2 py-2 text-sm flex items-center gap-x-2 rounded-[8px] ${filter === "" && "border-1 border-[#191B24]"}`}>
                  {filter === "" ? "Status" : filter}
                  <IoMdArrowDropdown />
                </div>
              </DialogTrigger>
              {filter !== "" && <X className="me-2" onClick={() => setFilter("")} />}
            </div>
            <DialogContent className="h-[100vh] px-0 w-full flex flex-col justify-start items-start">
              <div className="w-full border-b border-[#DEE2F4] px-3 pb-3">
                <div className="flex gap-x-3 items-center">
                  <DialogClose><X size={32} /></DialogClose>
                  <div className="text-[24px] font-medium">Property Status</div>
                </div>
              </div>
              <div className="px-3 mt-3 w-full">
                <form>
                  <RadioGroup value={filter} onValueChange={(value) => setFilter(value)} className=" flex flex-col w-full">
                    <div className="flex items-center justify-between w-full">
                      <label htmlFor="Unsold" className="text-xl">Unsold</label>
                      <DialogClose>
                        <RadioGroupItem value="Unsold" id="Unsold" />
                      </DialogClose>
                    </div>
                    <div className="flex items-center mt-3 justify-between w-full">
                      <label htmlFor="Sold" className="text-xl">Sold</label>
                      <DialogClose>
                        <RadioGroupItem value="Sold" id="Sold" />
                      </DialogClose>
                    </div>
                  </RadioGroup>
                </form>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog>
            <div className={`${filter2 !== "" && "bg-[#5A72B5] rounded-[5px] text-white border-[#5A72B5]"} flex items-center`}>
              <DialogTrigger>
                <div className={`px-2 py-2 flex items-center text-sm gap-x-2 rounded-[8px] ${filter2 === "" && "border-1 border-[#191B24]"}`}>
                  {filter2 === "" ? "Payment Type" : filter2}
                  <IoMdArrowDropdown />
                </div>
              </DialogTrigger>
              {filter2 !== "" && <X className="me-2" onClick={() => setFilter2("")} />}
            </div>
            <DialogContent className="h-[100vh] px-0 w-full flex flex-col justify-start items-start">
              <div className="w-full border-b border-[#DEE2F4] px-3 pb-3">
                <div className="flex gap-x-3 items-center">
                  <DialogClose><X size={32} /></DialogClose>
                  <div className="text-[24px] font-medium">Payment Status</div>
                </div>
              </div>
              <div className="px-3 w-full">
                <RadioGroup value={filter2} onValueChange={(value) => setFilter2(value)} className=" flex flex-col w-full">
                  <div className="flex border-b border-[#DEE2F4] pb-3 items-center justify-between w-full">
                    <label htmlFor="Cash" className="text-xl">Cash</label>
                    <DialogClose>
                      <RadioGroupItem value="Cash" id="Cash" />
                    </DialogClose>
                  </div>
                  <div className="flex flex-col border-b border-[#DEE2F4] pb-3">
                    <div className="flex items-center mt-3 justify-between w-full">
                      <label htmlFor="Loan" className="text-xl">Loan</label>
                      <DialogClose>
                        <RadioGroupItem value="Loan" id="Loan" />
                      </DialogClose>
                    </div>
                    <div className="flex items-center mt-3 justify-between w-full">
                      <label htmlFor="Application" className="text-xl">Application</label>
                      <DialogClose>
                        <RadioGroupItem value="Application" id="Application" />
                      </DialogClose>
                    </div>
                    <div className="flex items-center mt-3 justify-between w-full">
                      <label htmlFor="Process" className="text-xl">Process</label>
                      <DialogClose>
                        <RadioGroupItem value="Process" id="Process" />
                      </DialogClose>
                    </div>
                    <div className="flex items-center mt-3 justify-between w-full">
                      <label htmlFor="Sanctioned" className="text-xl">Sanctioned</label>
                      <DialogClose>
                        <RadioGroupItem value="Sanctioned" id="Sanctioned" />
                      </DialogClose>
                    </div>
                  </div>
                  <div className="flex items-center mt-3 justify-between w-full">
                    <label htmlFor="Cash & Loan" className="text-xl">Cash & Loan</label>
                    <DialogClose>
                      <RadioGroupItem value="Cash & Loan" id="Cash & Loan" />
                    </DialogClose>
                  </div>
                </RadioGroup>
              </div>
            </DialogContent>
          </Dialog>
          {(filter !== "" || filter2 !== "") && <div onClick={clearFilters} className="bg-red-500 text-white px-2 py-1 rounded-[5px]">Clear</div>}
        </div>
        {(filter !== "" || filter2 !== "") && <div className="mb-3">Showing {filteredFlats?.length} out of {sortedFlats?.length}</div>}
        {filteredFlats.map((flat, index) => (
          <ProjectListComp
            key={index}
            name={flat.customerName}
            series={flat.series}
            contact={flat.mobileNumber}
            deed={flat.isDeed}
            squareFeet={flat.squareFeet}
            bhk={flat.bhkSize}
            status={flat?.status}
            address={flat.address}
            flatId={flat._id}
            id={id}
            role={role}
            setisLoading={setisLoading}
            redirect={pathname}
          />
        ))}
        <div className={`${""} fixed bottom-32 right-14`}></div>
        {role && (
          <div className={`${""} fixed bottom-40 lg:bottom-36 right-20 lg:right-[42.5%]`}>
            <AddPlotDialog setisLoading={setisLoading} id={id} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectPage;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../ui/drawer";
import { Button } from "../ui/button";
import { deleteProject } from "../../redux/actions/projectAction";
import { useDispatch } from "react-redux";

function ProjectComp({ name, logo, id, teamId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  let longPressTimeout;

  const handleDelete = (id, teamId) => {
    dispatch(deleteProject(id, teamId));
  }

  const handleMouseDown = () => {
    longPressTimeout = setTimeout(() => {
      setIsOpen(true);
    }, 500); // Adjust the time as needed
  }

  const handleMouseUp = () => {
    clearTimeout(longPressTimeout);
  }
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default context menu
  }

  return (
    <>
      <div
        onClick={() => {
          if(id === "660023ebb88125e485ba5237" || id === "6601729035c1f6e9f7782308") {
            navigate(`/rent/${id}`);
          } else {
            navigate(`/wingproject/${id}`);
          }
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onContextMenu={handleContextMenu} // Prevent default context menu
        className=" flex flex-col justify-center gap-y-2 mb-4 items-center select-none"
      >
        <div className="sc active:bg-blue-100 h-[116px] flex justify-center items-center px-2" style={{ borderRadius: "10px" }}>
          <img src={logo} className="" alt="" />
        </div>
        <div className="text-sm font-[400] text-center">{name}</div>
      </div>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <DrawerContent className="pb-4 px-3">
          <DrawerClose onClick={() => setIsOpen(false)}>
            <Button
              variant="primary"
              type="button"
              onClick={() => handleDelete(id, teamId)}
              className="border-[1px] mt-5 rounded-[8px] border-[red] text-[red] h-[50px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-medium"
            >
              Delete project
            </Button>
          </DrawerClose>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ProjectComp;
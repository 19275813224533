import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { downSvg, upSvg } from "../svgs/svgs";

const TodayPayment = ({ isLoading, setisLoading }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.user);
  const [todayPayment, setTodayPayment] = useState([]);
  const [pastPayment, setPastPayment] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setisLoading(true);
    axios
      .get(`${baseUrl}/api/flat/getflatpayment/${user?.teamId}`)
      .then((res) => {
        setTodayPayment(res.data.todayPayments);
        setPastPayment(res.data.pastPayments);
      })
      .catch((err) => { })
      .finally(() => setisLoading(false));
  }, [user]);

  function convertPriceToIndianNumberingSystem(price) {
    // Split the price into an array of digits.
    const digits = price.toString().split("");

    // Reverse the array of digits.
    digits.reverse();

    // Add a comma after every three digits, starting from the right.
    for (let i = 3; i < digits.length; i += 3) {
      digits.splice(i, 0, ",");
    }

    // Join the array of digits back into a string.
    const indianPrice = digits.join("");
    const payment = indianPrice.split('').reverse().join('');

    // Return the Indian price.
    return payment;
  }

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <div className="h-[100vh] overflow-hidden">
      <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
        <div className=" flex justify-start items-center gap-x-1">
          <div
            onClick={() => navigate("/workspace")}
            className="flex p-2 rounded-full justify-center items-center"
          >
            <BiArrowBack size={24} />
          </div>
          <div className=" text-xl font-medium cursor-pointer">
            Today&apos;s Due-date list
          </div>
        </div>
      </div>
      <div className="h-[92%] my-3 overflow-y-auto">
        <>
          <div className="border-b px-3 border-[#E6E7F4]">
            {todayPayment.length === 0 ? (
              <>
                {!isLoading && (
                  <div className="text-[#8C0009] text-center text-xl font-medium pt-3 pb-4">
                    There's Nothing today!!
                  </div>
                )}
              </>
            ) : (
              <div>
                {todayPayment.map((item, index) => (
                  <div onClick={() => navigate(`/flat-details/${item.flatId}/${item.projectId}?redirect=${pathname}`)} key={index} className="bg-[#ECEDF9] p-3 mb-3 rounded-[8px]">
                    <div className="flex justify-between">
                      <div className="text-[18px] font-medium">{item.series}</div>
                      <div className="text-[#003C9A] font-medium">{convertPriceToIndianNumberingSystem(item.paymentAmount)}</div>
                    </div>
                    <div className="flex mt-2 justify-between">
                      <div className="text-[18px] font-medium">{item.projectName}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="px-3 mt-3 border-b border-[#E6E7F4]">
            <div className="mb-3 flex justify-between items-center">
              <div>Past Due-dates list</div>
              <div onClick={() => setIsShow(!isShow)}>{isShow ? upSvg : downSvg}</div>
            </div>
            <div className={`${!isShow ? "hidden" : "block"}`}>
              {pastPayment.length === 0 ? (
                <>
                  {!isLoading && (
                    <div className="text-[#8C0009] text-center text-xl font-medium pt-3 pb-4">
                      There's No Past Payments Yet!
                    </div>
                  )}
                </>
              ) : (
                <>
                  {pastPayment.map((item, index) => (
                    <div onClick={() => navigate(`/flat-details/${item.flatId}/${item.projectId}?redirect=${pathname}`)} key={index} className="bg-[#ECEDF9] p-3 mb-3 rounded-[8px]">
                      <div className="flex justify-between">
                        <div className="text-[18px] font-medium">{item.series}</div>
                        <div className="text-[#003C9A] font-medium">{convertPriceToIndianNumberingSystem(item.paymentAmount)}</div>
                      </div>
                      <div className="flex mt-2 justify-between">
                        <div className="text-[18px] font-medium">{item.projectName}</div>
                        <div className="text-[#F11] font-medium">{formatDate(item.dueDate)}</div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default TodayPayment;

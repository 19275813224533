import React, { useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "../ui/drawer";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

function AddDrawer({ isAdmin }) {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <div onClick={() => setIsOpen(true)}>
          <button
            className=" active:scale-[0.9] transition-all h-[62px] w-[62px] flex justify-center
             items-center plus absolute"
            style={{ borderRadius: "16px" }}
          >
            <Plus size={34} color="#fff"/>
          </button>
        </div>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle></DrawerTitle>
            <DrawerDescription className="w-full p-0 font-medium text-[20px]">
              {isAdmin ? (
                <>
                  <div className="w-full text-start py-[20px] px-[24px] border-b border-[#e7e0ec]" onClick={() => navigate("/taskteam")}>
                    Create new task
                  </div>
                  {/* <div className="w-full text-start p-2 border-b border-zinc-200">
                    Add inquiry record
                  </div> */}
                  <div className="w-full text-start py-[20px] px-[24px] cursor-pointer" onClick={() => navigate("/material")}>
                    Request material
                  </div>
                  {/* <div className="w-full text-start p-2">Post announcement</div> */}
                </>
              ) : (
                <>
                  <div className="w-full text-start py-[20px] px-[24px] cursor-pointer" onClick={() => navigate("/material")}>
                    Request material
                  </div>
                </>
              )}
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default AddDrawer;

import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TaskTeamList = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer.user);
    const teamUser = useSelector((state) => state.userReducer.teamUser);

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate("/workspace")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className="text-xl font-medium cursor-pointer">Tasks</div>
                </div>
            </div>
            <div className="p-3 h-[92%] overflow-auto">
                <div className="row p-0 gx-3">
                    {user?.role === "admin" && (
                        <>
                            {teamUser.map((item, index) => {
                                return (
                                    <div onClick={() => navigate(`/usertask/${item?.email}/${item?.userName}`)} key={index} className="col-4 mb-3">
                                        <img src={item.imageUrl} className="rounded-[8px]" alt="" />
                                        <div className="text-sm text-center mt-1 font-medium">{item.userName.split(" ")[0]}</div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {user?.role !== "admin" && (
                        <>
                            <div onClick={() => navigate(`/usertask/${user?.email}/${user?.userName}`)} className="col-4 mb-3">
                                <img src={user?.imageUrl} className="rounded-[8px]" alt="" />
                                <div className="text-sm text-center mt-1 font-medium">{user?.userName?.split(" ")[0]}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TaskTeamList;
import { UserButton, useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser } from "../redux/actions/userAction";
import { checkAuthenticatedUser } from "../hooks/authenticated-user";
import { Loader2 } from "lucide-react";

function HomePage({ isLoading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const { user } = useUser();

  const userEmail = user?.primaryEmailAddress.emailAddress;
  const User = useSelector((state) => state.userReducer.user);
  const isAuthenticated = checkAuthenticatedUser(User?.email);
  const [isMainLoading, setIsMainLoading] = useState(true);
  // console.log(User?.email);

  useEffect(() => {
    if (User.teamId != null) {
      navigate("/workspace")
    }
    if(!isLoading) setIsMainLoading(false);
  }, [navigate, User?.teamId, isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      setAllowed(true);
    } else {
      setAllowed(false);
    }
  }, [userEmail, isAuthenticated]);

  useEffect(() => {
    const createUser = async () => {
      try {
        const dbUser = {
          email: userEmail,
          userName: user.lastName ? user?.firstName + " " + user?.lastName : user.firstName,
          role: "",
          imageUrl: user?.imageUrl,
          userId: user.id,
          teamId: null,
          fcmToken: ""
        };
        dispatch(addUser(dbUser));
      } catch (error) {
        // hanlde Error
      }
    };

    createUser();
  }, [
    allowed,
    dispatch,
    user?.firstName,
    user?.lastName,
    userEmail,
    user?.imageUrl,
    user.id,
    isAuthenticated,
    user
  ]);

  return (
    <>
      {
        isMainLoading ? (
          <div className=" absolute top-0 h-[100vh] flex justify-center items-center w-full">
            <Loader2 className=" animate-spin" />
          </div>
        ) : (
          <div>
            <div className=" absolute top-4 right-4"><UserButton afterSignOutUrl="/" /></div>
            <div className=" absolute top-[76px] max-h-[100vh] px-3 w-full flex gap-y-10 flex-col justify-center items-center">
              <div>
                <div className=" font-[600]" style={{ fontSize: "58px" }}>Welcome!</div>
                <p className="text-center">{user.firstName} {user.lastName}</p>
                <p className="text-center font-normal text-lg">{user?.primaryEmailAddress.emailAddress}</p>
              </div>
              {
                allowed ? (
                  <div
                    className="bg-[#0066FA] rounded-[8px] font-[600] text-white cursor-pointer flex gap-x-3 justify-center items-center lg:w-[350px] w-full h-[60px]"
                    onClick={() => navigate("/createworkspace")}
                  >
                    <div className=" text-lg">Create workspace</div>
                  </div>
                ) : (
                  <div className=" rounded-[8px] font-[600] text-[red] flex gap-x-3 justify-center items-center lg:w-[350px] w-full h-[60px] cursor-not-allowed px-3 text-center">
                    You have not been joined to the workspace by admin
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
}

export default HomePage;

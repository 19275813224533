import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const HOC = (Components) => {
  const NewComponent = ({ setisLoading }) => {
    const user = useSelector((state) => state.userReducer.user);
    useEffect(() => {
      setIsAdmin(user?.role === "admin");
    }, [user?.role]);
    const [isAdmin, setIsAdmin] = useState(false);

    return (
      <>
        <div className="h-[100vh]">
          <div className="h-[88%]">
            <Components setisLoading={setisLoading} isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
          </div>
        </div>
      </>
    );
  };
  return NewComponent;
};

import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GET_USER, GET_USER_FROM_TEAM } from "../types/types";
import toast from "react-hot-toast";

export const getUser = (userId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios.get(`${baseUrl}/api/user/${userId}`)
    .then((res) => {
      dispatch({ type: GET_USER, user: res.data });
    })
    .catch(() => {
      // handle Error
    })
    .finally(() => setisLoading(false));
  }
}

export const addUser = (user) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/user`, user)
      .then((res) => {
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const updateUser = (id, user, userId, teamId) => {
  return (dispatch) => {
    axios.put(`${baseUrl}/api/user/${id}`, { user })
    .then((res) => {
      dispatch(getUser(userId));
      dispatch(getAllUserFromTeam(teamId))
    })
    .catch(() => {
      // handle Error
    })
  }
}

export const updateUserByEmail = (email, user, userId, message, setisLoading = () => {}) => {
  return (dispatch) => {
    axios.put(`${baseUrl}/api/user/updateUserByEmail/${email}`, user)
    .then((res) => {
      dispatch(getUser(userId));
      dispatch(getAllUserFromTeam("sangani123"));
      message && toast.success(message)
      setisLoading(false);
    })
    .catch((err) => {
    })
  }
}

export const sendInvitation = () => {
}

export const getAllUserFromTeam = (teamId) => {
  return (dispatch) => {
    axios.get(`${baseUrl}/api/user/teamUsers/${teamId}`)
    .then((res) => {
      dispatch({ type:GET_USER_FROM_TEAM, teamUser: res.data });
    })
    .catch(() => {
      // handle Error
    })
  }
}

export const removeUserById = (id, teamId) => {
  return (dispatch) => {
    axios.delete(`${baseUrl}/api/user/${id}`)
    .then((res) => {
      dispatch(getAllUserFromTeam(teamId));
      toast.success("User kicked");
    })
    .catch(() => {
      toast.success("Something went wrong");
      // handle Error
    })
  }
}
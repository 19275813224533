import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import AssignTaskDialog from "../components/customUi/assign-task-dialog";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTaskByUserName } from "../redux/actions/taskAction";
import { blueTaskSvg, whiteTaskSvg } from "../svgs/svgs";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTrigger,
} from "../components/ui/dialog";
import { IoMdArrowDropdown } from "react-icons/io";
import { X } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

const UserTaskPage = ({ setisLoading, isLoading }) => {
    const [filter, setFilter] = useState(localStorage.getItem("filterTask") || "");
    const selfTask = useSelector((state) =>
        state.taskReducer.selfTask.slice().reverse()
    );
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer.user);
    const { email, name } = useParams();
    const currentDate = Date.now();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTaskByUserName(email, user?.teamId, setisLoading));
    }, [user]);

    // Sort tasks based on isCompleted property
    const sortedTasks = [...selfTask].sort((a, b) => {
        if (a.isCompleted && !b.isCompleted) return 1;
        if (!a.isCompleted && b.isCompleted) return -1;
        return 0;
    });

    // Filter tasks based on the selected filter
    const filteredTasks = sortedTasks.filter(item => {
        if (filter === "Complete") {
            return item.isCompleted;
        } else if (filter === "Incomplete") {
            return !item.isCompleted;
        } else {
            return true;
        }
    });

    // Update filter state and store it in local storage
    const handleFilterChange = (value) => {
        setFilter(value);
        localStorage.setItem("filterTask", value);
    };

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate("/taskteam")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className="text-xl font-medium cursor-pointer">{name.split(" ")[0]}</div>
                </div>
            </div>
            <div className="h-[92%] overflow-auto p-3">
                <div className="mb-3 flex">
                    <Dialog>
                        <div className={`${filter !== "" && "bg-[#5A72B5] rounded-[5px] text-white border-[#5A72B5]"} flex items-center`}>
                            <DialogTrigger>
                                <div className={`px-2 py-2 text-sm flex items-center gap-x-2 rounded-[8px] ${filter === "" && "border-1 border-[#191B24]"}`}>
                                    {filter === "" ? "Task status" : filter}
                                    <IoMdArrowDropdown />
                                </div>
                            </DialogTrigger>
                            {filter !== "" && <X className="me-2" onClick={() => handleFilterChange("")} />}
                        </div>
                        <DialogContent className="h-[100vh] px-0 w-full flex flex-col justify-start items-start">
                            <div className="w-full border-b border-[#DEE2F4] px-3 pb-3">
                                <div className="flex gap-x-3 items-center">
                                    <DialogClose><X size={32} /></DialogClose>
                                    <div className="text-[24px] font-medium">Task Status</div>
                                </div>
                            </div>
                            <div className="px-3 mt-3 w-full">
                                <form>
                                    <RadioGroup value={filter} onValueChange={handleFilterChange} className=" flex flex-col w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <label htmlFor="Complete" className="text-xl">Complete</label>
                                            <DialogClose>
                                                <RadioGroupItem value="Complete" id="Complete" />
                                            </DialogClose>
                                        </div>
                                        <div className="flex items-center mt-3 justify-between w-full">
                                            <label htmlFor="Incomplete" className="text-xl">Incomplete</label>
                                            <DialogClose>
                                                <RadioGroupItem value="Incomplete" id="Incomplete" />
                                            </DialogClose>
                                        </div>
                                    </RadioGroup>
                                </form>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                {!isLoading && (
                    <>
                        {filteredTasks.map((item, index) => (
                            <div onClick={() => navigate(`/taskdetails/${item._id}/${email}/${name}/${item.series}`)} key={index} className={`flex px-3 py-3 ${item.isCompleted ? "bg-[#4CAF50] text-white" : "bg-[#ECEDF9] text-black"} mb-3 rounded-[8px] justify-between gap-x-3`}>
                                <div className="flex text-[18px] font-medium gap-x-3 items-center">
                                    <div>{item.series}.</div>
                                    <div>{item.taskTitle}</div>
                                </div>
                                {(item.assignTo !== item.createdBy) && (
                                    <div>{item.isCompleted ? whiteTaskSvg : blueTaskSvg}</div>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className={`${""} fixed bottom-40 lg:bottom-36 right-20 lg:right-[42.5%]`}>
                <AssignTaskDialog length={selfTask.length} assignTo={email} currentDate={currentDate} setisLoading={setisLoading} userName={name} teamId={user?.teamId} />
            </div>
        </div>
    );
}

export default UserTaskPage;
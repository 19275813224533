import React, { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import MaterialDialog from "../components/customUi/material-dialog";
import MaterialComp from "../components/customUi/material-comp";
import { useDispatch, useSelector } from "react-redux";
import { getMaterial } from "../redux/actions/materialAction";

function MaterialPage({ setisLoading }) {
  const navigate = useNavigate();
  const material = useSelector((state) => state.materialReducer.material.slice().reverse());
  const User = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMaterial(User?.teamId, setisLoading));
  }, [User?.teamId, dispatch, setisLoading]);

  return (
    <div className="h-[90vh]">
      <div className=" h-[8vh] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
        <div className=" flex justify-start items-center gap-x-1">
          <div>
            <div className="flex p-2 rounded-full justify-center items-center">
              <BiArrowBack onClick={() => {
                setTimeout(() => {
                  navigate("/workspace")
                }, 0);
              }} size={24} />
            </div>
          </div>
          <div className=" text-xl font-semibold cursor-pointer">Material</div>
        </div>
      </div>
      <div className="h-[90vh] mt-3 relative overflow-y-auto">
        {material.map((material, index) => (
          <MaterialComp
            key={index}
            name={material.createdBy}
            material={material.material}
            quantity={material.quantity}
            project={material.project}
            date={material.createdAt}
            status={material.isOrdered}
            imageUrl={material.imageUrl}
            quantityType={material.quantityType}
            id={material._id}
            teamId={material.teamId}
            setisLoading={setisLoading}
            role={User?.role}
          />
        ))}

        {material.length <= 0 && (
          <>
            <div className="h-[50vh] w-full justify-center items-center">
              <div className="h-full px-5 flex flex-col justify-center items-center text-center">
                <div className=" text-[28px] font-semibold">
                  No material request at the moment!
                </div>
                <p className=" text-[18px] text-[#49454F]">click + icon below to create a material request.</p>
              </div>
            </div>
          </>
        )}
        <div className={`${""} fixed bottom-40 lg:bottom-36 right-20 lg:right-[42.5%]`}>
          <MaterialDialog setisLoading={setisLoading}/>
        </div>
      </div>
    </div>
  );
}

export default MaterialPage;
import { X } from "lucide-react";
import React, { useRef } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Checkbox } from "../components/ui/checkbox";
import { Button } from "../components/ui/button";

function FilterPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const formRef = useRef();

  const wings = ["A", "B", "C", "D", "E"];
  const bhk = [1, 2, 3];
  const floor = [1, 2, 3, 4, 5];

  return (
    <div className="h-[100vh] max-w-[100vw] overflow-hidden">
      <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-zinc-200">
        <div className=" flex justify-start items-center gap-x-1">
          <div className=" p-2 rounded-full active:bg-blue-100">
            <BiArrowBack onClick={() => {
              setTimeout(() => {
                navigate(`/project/${id}`)
              }, 100);
            }} size={24} />
          </div>
          <div className=" text-xl font-semibold">Filter</div>
        </div>
        <div
          onClick={() => formRef.current.reset()}
          className="flex gap-x-1 text-sm text-red-500 justify-center items-center cursor-pointer"
        >
          <X size={18} /> Reset filter
        </div>
      </div>
      <div className="max-h-[92%]">
        <form ref={formRef}>
          <div className="px-3 mt-3">
            <div className="mb-2">Type</div>
            <RadioGroup className=" flex">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Sold" id="Sold" />
                <label htmlFor="Sold">Sold</label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Unsold" id="Unsold" />
                <label htmlFor="Unsold">Unsold</label>
              </div>
            </RadioGroup>
          </div>
          <div className=" border-b border-zinc-200 my-3" />
          <div className="px-3">
            <div className="mb-2">Wing</div>
            <div className=" flex gap-x-4 justify-start items-center">
              {wings.map((wing, index) => (
                <>
                  <div>
                    <Checkbox key={index} id={wing} name={wing} value={wing} />
                    <label className="ms-2" for={wing}>
                      {wing}
                    </label>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className=" border-b border-zinc-200 my-3" />
          <div className="px-3">
            <div className="mb-2">BHK</div>
            <div className=" flex gap-x-4 justify-start items-center">
              {bhk.map((bhk, index) => (
                <>
                  <div>
                    <Checkbox key={index} id={bhk} name={bhk} value={bhk} />
                    <label className="ms-2" for={bhk}>
                      {bhk}
                    </label>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className=" border-b border-zinc-200 my-3" />
          <div className="px-3">
            <div className="mb-2">Floor</div>
            <div className=" flex gap-x-4 justify-start items-center">
              {floor.map((floor, index) => (
                <>
                  <div>
                    <Checkbox
                      key={index}
                      id={floor}
                      name={floor}
                      value={floor}
                    />
                    <label className=" ms-2" for={floor}>
                      {floor}
                    </label>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className=" border-b border-zinc-200 my-3" />
          <div className="px-3">
            <div className="mb-2">Deed</div>
            <div className=" flex gap-x-4 justify-start items-center">
              <div>
                <Checkbox id={"done"} name={"done"} value={"done"} />
                <label className="ms-2" for={"done"}>
                  Done
                </label>
              </div>
              <div>
                <Checkbox id={"baki"} name={"baki"} value={"baki"} />
                <label className="ms-2" for={"baki"}>
                  Baki
                </label>
              </div>
            </div>
          </div>
          <div className=" border-b border-zinc-200 my-3" />
          <div className="px-3">
            <div className="mb-2">Square foot</div>
            <div className=" flex justify-start items-center gap-x-2">
              <input
                type="text"
                className="w-[100px] text-center sc outline-none h-[50px] px-2"
                placeholder="0"
                style={{ borderRadius: "5px" }}
                autoFocus={false}
              />
              <div className="mx-2">to</div>
              <input
                type="text"
                className="w-[100px] text-center sc outline-none h-[50px] px-2"
                placeholder="4000sqft"
                style={{ borderRadius: "5px" }}
                autoFocus={false}
              />
            </div>
            <Button
              variant="primary"
              className="w-full mt-4 p-[24px] plus text-white"
              style={{ borderRadius: "5px" }}
            >
              Apply
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FilterPage;

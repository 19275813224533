import React, {  } from "react";
import { Circle } from "lucide-react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function TaskComp({ task, status, imageUrl, id, path }) {
  const navigate = useNavigate();

  return (
    <>
      {!imageUrl && (
        <div
          onClick={() => navigate(`/taskdetails/${id}/${path}`)}
          className="sc select-none mb-2 flex items-center gap-x-3 justify-start px-3 py-[16px]"
          style={{ borderRadius: "8px" }}
        >
          {!status ? (
            <div >
              <Circle size={24} />
            </div>
          ) : (
            <div>
              <FaCheckCircle size={24} color="green" />
            </div>
          )}
          <div
            className={`${status && " line-through osv"
              } text-[18px] os`}
          >
            {task.taskTitle}
          </div>
        </div>
      )}
      {imageUrl && (
          <div onClick={() => navigate(`/taskdetails/${id}/${path}`)} className="w-full text-start">
            <div
              className="flex justify-between items-center px-3 py-[16px] sc mb-2"
              style={{ borderRadius: "8px" }}
            >
              <div className=" flex items-center gap-x-3 justify-start">
                {!status ? (
                  <div>
                    <Circle size={24} />
                  </div>
                ) : (
                  <div>
                    <FaCheckCircle size={24} color="green" />
                  </div>
                )}
                <div className={`${status && " line-through text-[#49454F]"} text-[17px] px-1`}>
                  {task}
                </div>
              </div>
              <div className="min-w-[40px]">
                <img
                  src={imageUrl}
                  className="h-[40px] w-[40px] object-cover"
                  style={{ borderRadius: "50%" }}
                  alt="icon"
                />
              </div>
            </div>
          </div>
      )}
    </>
  );
}

export default TaskComp;

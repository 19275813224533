import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import toast from "react-hot-toast";
import { GETRENT } from "../types/types";

export const getTeamRent = (teamId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/rent/${teamId}`)
      .then((res) => {
        dispatch({ type: GETRENT, data: res.data });
      })
      .catch((err) => {})
      .finally(() => setisLoading(false));
  };
};

export const addRent = (rent, setisLoading) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/rent`, rent)
      .then((res) => {
        dispatch(getTeamRent(rent?.teamId, setisLoading));
        if (res.status === 200) toast.success("Rent added successfully");
      })
      .catch((err) => {});
  };
};

export const editRent = (id, rent, getFunction = () => {}, setisLoading = () => {}) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/rent/${id}`, rent)
      .then((res) => {
        dispatch(getTeamRent(rent?.teamId, setisLoading));
        if (res.status === 200) toast.success("Rent edited successfully");
        getFunction();
      })
      .catch((err) => {});
  };
};

import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addRent, editRent } from "../redux/actions/rentAction";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { dateSvg } from "../svgs/svgs";

const AddRent = ({ setisLoading }) => {
    const navigate = useNavigate();
    let [rent, setRent] = useState({});
    const user = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const { projectId } = useParams();

    useEffect(() => {
        if (id !== null) {
            axios.get(`${baseUrl}/api/rent/get/${id}`)
                .then((res) => {
                    setRent(res.data);
                })
                .catch((err) => { });
        }
    }, [id, dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        rent = { ...rent, [name]: value };
        setRent({ ...rent });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        rent = { ...rent, teamId: user?.teamId };
        setRent({ ...rent });
        if (id !== null) {
            dispatch(editRent(id, rent, setisLoading));
        } else {
            dispatch(addRent(rent, setisLoading));
        }
        navigate(`/rent/${projectId}`);
    }

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate(`/rent/${projectId}`)} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">{id === null ? "Add Rent" : "Edit Rent"}</div>
                </div>
            </div>
            <div className="h-[92%] overflow-auto px-3 py-3">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="mb-1">Project Name*</label>
                        <input
                            type="text"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter project name"
                            autoFocus={false}
                            name="projectName"
                            onChange={handleChange}
                            value={rent?.projectName}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-1">Property Number*</label>
                        <input
                            type="text"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter property number"
                            autoFocus={false}
                            name="propertyNumber"
                            value={rent?.propertyNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-1">Bhadut Name*</label>
                        <input
                            type="text"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter bhadut name"
                            autoFocus={false}
                            name="BhadutName"
                            value={rent?.BhadutName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-1">Rent Amount*</label>
                        <input
                            type="number"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter Rent amount"
                            autoFocus={false}
                            name="rentAmount"
                            value={rent?.rentAmount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-1">Deposit*</label>
                        <input
                            type="number"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter Deposit amount"
                            autoFocus={false}
                            name="deposit"
                            value={rent?.deposit}
                            step={"any"}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="mb-1">Mobile No.*</label>
                        <input
                            type="number"
                            className="w-full sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                            placeholder="Enter Mobile Number"
                            autoFocus={false}
                            name="mobileNumber"
                            value={rent?.mobileNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="rentStart" className="mb-1">Rent starting date</label>
                        <div className="w-full bg-[#ecedf9] flex rounded-[5px] overflow-hidden h-[50px]">
                            <input
                                type="date"
                                className="w-[80%] sc ps-[16px] pe-[16px] rounded-[5px] outline-none h-full text-start"
                                placeholder="Add Date"
                                name="rentStart"
                                value={rent?.rentStart}
                                onChange={handleChange}
                                id="rentStart"
                            />
                            <label htmlFor="rentStart" className="flex justify-center items-center w-[20%] h-full">{dateSvg}</label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <label htmlFor="depositeDate" className="mb-1">Deposite date</label>
                        <div className="w-full bg-[#ecedf9] flex rounded-[5px] overflow-hidden h-[50px]">
                            <input
                                type="date"
                                className="w-[80%] sc ps-[16px] pe-[16px] rounded-[5px] outline-none h-full text-start"
                                placeholder="Add Date"
                                name="depositeDate"
                                value={rent?.depositeDate}
                                onChange={handleChange}
                                id="depositeDate"
                            />
                            <label htmlFor="depositeDate" className="flex justify-center items-center w-[20%] h-full">{dateSvg}</label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button className="plus w-full h-[50px] rounded-[8px] text-white" type="submit">
                            {id === null ? "Create" : "Edit"}
                        </button>
                        <button onClick={() => navigate(`/rent/${projectId}`)} className="discard mt-3 w-full h-[50px] rounded-[8px]" type="submit">
                            Discard
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddRent;
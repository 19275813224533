import React, { useEffect, useState } from "react";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../ui/dialog";
import { Plus } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { addMaterial } from "../../redux/actions/materialAction";

function MaterialDialog({ setisLoading }) {
  const user = useSelector((state) => state.userReducer.user);
  const projects = useSelector((state) => state.projectReducer.projects);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line
    material = { ...material, createdBy: user?.userName, imageUrl: user?.imageUrl, project: projects[0]?.projectName }
    setMaterial({ ...material });
  }, [user?.userName, user?.imageUrl, projects[0]?.projectName]);

  const dispatch = useDispatch();
  let [material, setMaterial] = useState({
    material: "Cement/સિમેન્ટ",
    quantity: "",
    project: "",
    quantityType: "Bags",
    createdBy: user?.userName,
    imageUrl: user?.imageUrl,
    teamId: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    material = { ...material, teamId: user?.teamId }
    setMaterial({ ...material });
    dispatch(addMaterial(material, material.teamId, setisLoading));
    setMaterial({
      material: "Cement/સિમેન્ટ",
      quantity: "",
      project: projects[0]?.projectName,
      quantityType: "Bags",
      createdBy: user?.userName,
      imageUrl: user?.imageUrl,
      teamId: user?.teamId
    })
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <button
            className="h-[62px] w-[62px] flex justify-center
             items-center plus absolute active:scale-[0.9] transition-all"
            style={{ borderRadius: "16px" }}
          >
            <Plus size={34} color="#fff" />
          </button>
        </DialogTrigger>
        <DialogContent
          className=" top-60 w-[95vw]"
          style={{ borderRadius: "10px" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="row gap-x-3 p-0 g-0 text-[18px]">
              <div className="col">
                <div>
                  <label className="my-2" htmlFor="">
                    Material*
                  </label>
                  <Select
                    defaultValue={material.material}
                    onValueChange={(value) => {
                      if (value?.length >= 1) setIsDisable(false);
                      if (value === "Cement/સિમેન્ટ") material.quantityType = "Bags"
                      if (value === "Steel bar/સ્ટીલ બાર") material.quantityType = "Pieces"
                      if (value === "Sand/રેતી" || value === "Kapachi/કપચી" || value === "Brick/ઈંટ") material.quantityType = "Gadi/ગાડી"
                      material = { ...material, material: value }
                      setMaterial({ ...material });
                    }}
                  >
                    <SelectTrigger
                      className="w-full px-3 sc h-[50px] text-sm shadow-none border-0"
                      style={{ borderRadius: "5px" }}
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="" style={{ borderRadius: "5px" }}>
                      <SelectItem className="select" value="Cement/સિમેન્ટ">
                        Cement/સિમેન્ટ
                      </SelectItem>
                      <SelectItem className="select" value="Sand/રેતી">
                        Sand/રેતી
                      </SelectItem>
                      <SelectItem className="select" value="Brick/ઈંટ">
                        Brick/ઈંટ
                      </SelectItem>
                      <SelectItem className="select" value="Steel bar/સ્ટીલ બાર">
                        Steel bar/સ્ટીલ બાર
                      </SelectItem>
                      <SelectItem className="select" value="Kapachi/કપચી">
                        Kapchi/કપચી
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="col">
                <label className="my-2" htmlFor="">
                  Quantity*
                </label>
                <br />
                <div
                  className="w-full flex justify-center items-center sc"
                  style={{ borderRadius: "5px" }}
                >
                  <input
                    type="number"
                    className="w-[50%] text-lg bg-transparent outline-none h-[50px] ps-[24px]"
                    placeholder="0"
                    value={material.quantity}
                    onChange={(e) => {
                      const quantityValue = parseInt(e.target.value); // or +e.target.value
                      setIsDisable(false);
                      material = { ...material, quantity: quantityValue }
                      setMaterial({ ...material });
                    }}
                  />
                  <div className="w-[50%] text-sm text-center">{material.quantityType}</div>
                </div>
              </div>
            </div>
            <div>
              <label className="text-lg my-2" htmlFor="">
                Project/પ્રોજેક્ટ
              </label>
              <Select
                defaultValue={material.project}
                className="sc"
                onValueChange={(value) => {
                  if (value?.length >= 1) setIsDisable(false);
                  material = { ...material, project: value }
                  setMaterial({ ...material });
                }}
              >
                <SelectTrigger
                  className="w-full sc px-3 h-[50px] shadow-none border-0 text-lg"
                  style={{ borderRadius: "5px" }}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="" style={{ borderRadius: "5px" }}>
                  {
                    projects.map((project) => (
                      <SelectItem key={project._id} className="select" value={project.projectName}>
                        {project.projectName}
                      </SelectItem>
                    ))
                  }
                </SelectContent>
              </Select>
            </div>
            <DialogClose disabled={isDisable} className="w-full">
              <Button
                disabled={isDisable}
                type="submit"
                variant={"primary"}
                className="flex active:bg-opacity-70 gap-x-2 text-[18px] mt-4 h-[50px] px-[16px] py-[24px] text-white justify-center items-center plus w-full font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Create
              </Button>
            </DialogClose>
            <DialogClose className="w-full">
              <Button
                onClick={() => setMaterial({
                  material: "Cement",
                  quantity: "",
                  project: projects[0]?.projectName,
                  quantityType: "TON",
                  createdBy: user?.userName,
                  imageUrl: user?.imageUrl,
                  teamId: "sangani123"
                })}
                variant="primary"
                type="button"
                className="border-[1px] mt-3 discard h-[48px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Discard
              </Button>
            </DialogClose>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MaterialDialog;
import { useLocation, useNavigate } from "react-router-dom";
import { activitySvg, fillActivitySvg, fillHomeSvg, fillMyTaskSvg, fillTeamSvg, homeSvg, myTaskSvg, teamSvg } from "../svgs/svgs";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Tab = ({ isAdmin }) => {

    const { pathname } = useLocation();
    const selfTask = useSelector((state) =>
        state.taskReducer.selfTask
    );
    const [isNew, setIsNew] = useState(selfTask.filter(x => x.isSeen !== true).length !== 0);

    useEffect(() => {
        setIsNew(selfTask.filter(x => x.isSeen !== true).length !== 0);
    }, [selfTask]);

    const navigate = useNavigate();

    const navigationList = [
        {
            name: "Home",
            icon: homeSvg,
            fillIcon: fillHomeSvg,
            href: "/workspace",
        },
        {
            name: "Activity",
            icon: activitySvg,
            fillIcon: fillActivitySvg,
            href: "/activity",
        },
        {
            name: "Team",
            icon: teamSvg,
            fillIcon: fillTeamSvg,
            href: "/team",
        },
    ];
    // const navigationList2 = [
    //     {
    //         name: "Home",
    //         icon: homeSvg,
    //         fillIcon: fillHomeSvg,
    //         href: "/workspace",
    //     },
    //     {
    //         name: "My Tasks",
    //         icon: myTaskSvg,
    //         fillIcon: fillMyTaskSvg,
    //         href: "/taskteam",
    //     },
    // ];

    return (
        <>
            {
                (pathname === "/workspace" || pathname === "/team" || pathname === "/activity") && (
                    <div className={`fixed bottom-0 h-[12%] bg-[#FAF8FF] w-full lg:w-[500px] ${isAdmin && "border-t border-[#e7eoec]"}`}>
                        <div
                            className={` ${isAdmin ? "" : "px-[15px] lg:px-10"} flex ${isAdmin ? "justify-between" : "justify-evenly"
                                } items-center pb-2 h-full`}
                        >
                            {isAdmin && (
                                <>
                                    {navigationList.map((navigator, index) => (
                                        <div
                                            className={`w-full`}
                                            key={index}
                                        >
                                            <div
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        navigate(navigator.href);
                                                    }, 0);
                                                }}
                                                key={index}
                                                className={`select-none transition-all group flex flex-col cursor-pointer justify-center items-center`}
                                            >
                                                <div className={`${pathname === navigator.href ? "bg-[#ABC0FF] w-[65px]" : "w-0"} flex justify-center items-center px-[20px] py-1 rounded-[20px] transition-all`}
                                                >
                                                    <button
                                                    >
                                                        {pathname !== navigator.href ? navigator.icon : navigator.fillIcon}
                                                    </button>
                                                </div>
                                                <div
                                                    className={`${pathname === navigator.href ? "os" : "osv"
                                                        } font-semibold text-sm mt-1 whitespace-nowrap`}
                                                >
                                                    {navigator.name}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                            {/* {!isAdmin && (
                                <>
                                    {navigationList2.map((navigator, index) => (
                                        <div
                                            className={`${navigator.name === "Team" && "hidden"} s:px-[10px] sm:px-[15px] w-full`}
                                            key={index}
                                        >
                                            <div
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        navigate(navigator.href);
                                                    }, 0);
                                                }}
                                                key={index}
                                                className={`select-none transition-all group flex flex-col cursor-pointer justify-center items-center`}
                                            >
                                                <div className={`${pathname === navigator.href ? "bg-[#ABC0FF] w-[65px]" : "w-0"} flex justify-center items-center px-[20px] py-1 rounded-[20px] transition-all`}
                                                >
                                                    <button
                                                    >
                                                        {pathname !== navigator.href ? navigator.icon : navigator.fillIcon}
                                                    </button>
                                                </div>
                                                <div
                                                    className={`${pathname === navigator.href ? "os" : "osv"
                                                        } font-semibold relative text-sm mt-1 whitespace-nowrap`}
                                                >
                                                    {navigator.name}
                                                    {isNew && <div className={`absolute left-10 top-[-180%] p-1 bg-red-500 rounded-full ${navigator.name === "Home" && "hidden"}`}></div>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )} */}
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default Tab;
import React from 'react';
import { deleteSvg } from '../../svgs/svgs';
import {
    Dialog,
    DialogTrigger,
    DialogClose,
    DialogContent,
} from "../ui/dialog"
import { Button } from '../ui/button';
import { useDispatch } from 'react-redux';
import { deleteTask } from '../../redux/actions/taskAction';
import { useNavigate } from 'react-router-dom';

function DeleteTask({ id, teamId, email, name, setisLoading }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        dispatch(deleteTask(id, teamId, setisLoading ,email));
        navigate(`/usertask/${email}/${name}`);
    }

    return (
        <Dialog>
            <DialogTrigger>
                {deleteSvg}
            </DialogTrigger>
            <DialogContent
                className=" text-center w-[95%]"
                style={{ borderRadius: "5px" }}
            >
                <div className=" text-xl font-semibold">Are you sure</div>
                <div>You want to delete this task</div>
                <div className=" flex justify-between items-center">
                    <DialogClose>
                        <Button
                            variant="primary"
                            className=" bg-indigo-500 text-white font-semibold"
                            style={{ borderRadius: "5px" }}
                        >
                            Cancel
                        </Button>
                    </DialogClose>
                    <DialogClose>
                        <Button
                            onClick={() => handleDelete(id)}
                            variant="primary"
                            className=" bg-red-500 text-white font-semibold"
                            style={{ borderRadius: "5px" }}
                        >
                            Delete
                        </Button>
                    </DialogClose>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteTask

import axios from "axios";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../lib/base-url";
import { Plus } from "lucide-react";
import { useDispatch } from "react-redux";
import { editRent } from "../redux/actions/rentAction";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { dateSvg, smallDeleteSvg } from "../svgs/svgs";

const RentPayment = ({ setisLoading }) => {
    const navigate = useNavigate();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const { id } = useParams();
    const [rent, setRent] = useState({
        rentComplete: []
    });
    const [rentDate, setRentDate] = useState(new Date().toISOString().slice(0, 10));
    const [rentAmount, setRentAmount] = useState(rent?.rentAmount);
    
    const currentDate = new Date();
    const [month, setMonth] = useState(months[currentDate.getMonth()]);

    const handleDateChange = (event) => {
        const newRentDate = event.target.value;
        setRentDate(newRentDate);

        // Parse the newRentDate to a Date object
        const date = new Date(newRentDate);
        // Get the month index
        const monthIndex = date.getMonth();
        // Get the month name from the months array
        const monthName = months[monthIndex];
        // Update the rentMonthName state
        setMonth(monthName);
    };

    const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
    const dispatch = useDispatch();
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = currentDate.getMonth();

    useEffect(() => {
        getFunction();
    }, [id]);

    useEffect(() => {
        if (rent.rentComplete.length > 0) {
            findMissingMonths();
        }
    }, []);

    const getFunction = () => {
        axios.get(`${baseUrl}/api/rent/get/${id}`)
            .then((res) => {
                setRent(res.data)
                setRentAmount(res.data.rentAmount)
            })
            .catch((err) => { });
    }

    const updateRent = (id) => {
        if (rent.rentStatus) {
            const currentDate = new Date();
            const currentMonth = month;
            const currentYear = currentDate.getFullYear();
            const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;

            let updatedRentComplete;
            if (rent.rentComplete && rent.rentComplete.length === 0) {
                updatedRentComplete = [{
                    month: currentMonth,
                    year: currentYear,
                    amount: Number(rentAmount),
                    completeLength: 1,
                    date: rentDate
                }];
            } else {
                const lastEntry = rent.rentComplete[rent.rentComplete.length - 1];
                let nextCompleteLength = lastEntry.completeLength + 1;

                // If last month is December, reset completeLength to 0
                if (lastEntry.month === 'December') {
                    nextCompleteLength = 0;
                }

                updatedRentComplete = [...rent.rentComplete, {
                    month: currentMonth,
                    year: currentYear,
                    amount: Number(rentAmount),
                    completeLength: nextCompleteLength,
                    date: rentDate
                }];
            }

            dispatch(editRent(id, { rentComplete: updatedRentComplete }, getFunction, setisLoading));
        }
    }

    const findMissingMonths = () => {
        const missingMonths = [];
        const lastEntryMonthIndex = months.indexOf(rent.rentComplete[rent.rentComplete.length - 1].month);

        for (let i = lastEntryMonthIndex + 1; i < currentMonthIndex; i++) {
            missingMonths.push({
                month: months[i],
                year: currentYear,
                amount: 0,
                completeLength: rent.rentComplete[rent.rentComplete.length - 1].completeLength,
            });
        }

        const updatedRentComplete = [...rent.rentComplete, ...missingMonths];
        if (missingMonths.length > 0 && rent.rentStatus === false) {
            dispatch(editRent(id, { rentComplete: updatedRentComplete }, getFunction, setisLoading));
        }
    }

    const deleteRentItem = (index) => {
        const updatedRentComplete = rent.rentComplete.filter((_, i) => i !== index);
        dispatch(editRent(id, { rentComplete: updatedRentComplete }, getFunction, setisLoading));
    }

    const buttonText = months[currentDate.getMonth()];

    const totalAmount = rent?.rentComplete.reduce((total, item) => total + item.amount, 0);

    function convertPriceToIndianNumberingSystem(price) {
        // Split the price into an array of digits.
        const digits = price.toString().split("");

        // Reverse the array of digits.
        digits.reverse();

        // Add a comma after every three digits, starting from the right.
        for (let i = 3; i < digits.length; i += 3) {
            digits.splice(i, 0, ",");
        }

        // Join the array of digits back into a string.
        const indianPrice = digits.join("");
        const payment = indianPrice.split('').reverse().join('');

        // Return the Indian price.
        return payment;
    }

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate(-1)} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">Rents</div>
                </div>
                <div className={`${rent.rentStatus === false && "hidden"}`}>
                    <Dialog>
                        <DialogTrigger className="plus flex gap-x-2 px-3 py-2 text-white rounded-[8px]">
                            <Plus /> {buttonText}
                        </DialogTrigger>
                        <DialogContent
                            className=" text-start w-[95%] rounded-[5px]"
                        >
                            <div className=" text-[20px] font-[500]">Add Rents for {buttonText} {currentYear}</div>
                            <div className="flex font-[500] text-[18px] justify-between items-center gap-x-[5px]">
                                <div>Amount:</div>
                                <input
                                    type="number"
                                    className="w-[55%] sc ps-[16px] rounded-[8px] outline-none h-[50px]  text-start"
                                    placeholder="0000"
                                    autoFocus={false}
                                    value={rentAmount}
                                    onChange={(e) => setRentAmount(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex font-[500] text-[18px] justify-between items-center gap-x-[5px]">
                                <div>Date:</div>
                                <div>
                                    <div className="w-full flex rounded-[5px] overflow-hidden h-[50px]">
                                        <input
                                            type="date"
                                            className="w-full min-w-[140px] sc ps-[16px] pe-[16px] rounded-[5px] outline-none h-full text-start bg-transparent"
                                            placeholder="Add Date"
                                            name="depositeDate"
                                            id="depositeDate"
                                            value={rentDate}
                                            onChange={handleDateChange}
                                        />
                                        <label htmlFor="depositeDate" className="flex justify-center items-center w-[20%] h-full">{dateSvg}</label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-between gap-x-[16px] items-center">
                                <DialogClose className="w-full">
                                    <Button
                                        variant="primary"
                                        className="plus text-[18px] font-[500] h-[50px] w-full text-white rounded-[5px]"
                                        onClick={() => updateRent(id)}
                                    >
                                        Add
                                    </Button>
                                </DialogClose>
                                <DialogClose className="w-full">
                                    <Button
                                        variant="primary"
                                        className="discard text-black text-[18px] font-[500] h-[50px] w-full rounded-[5px]"
                                    >
                                        discard
                                    </Button>
                                </DialogClose>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <div className="h-[92%] overflow-auto">
                {rent?.rentComplete.map((item, index) => (
                    <div key={index} className={`px-3 py-3 flex justify-between border-b border-[#e7eoec] ${item.amount === 0 && "text-[#FF3333]"}`}>
                        <div className="font-medium text-[16px]">{item?.date && item.date.split("/")[0].split("-")[2]} {item.month}, {item.year}</div>
                        <div className="flex items-center gap-x-5">
                            <div className="font-medium text-[16px]">{item.amount === 0 ? "Not rented" : convertPriceToIndianNumberingSystem(item.amount)}</div>
                            <Dialog>
                                <DialogTrigger>
                                    {smallDeleteSvg}
                                </DialogTrigger>
                                <DialogContent
                                    className=" text-center w-[95%] rounded-[5px]"
                                >
                                    <div className=" text-xl font-semibold">Delete this entry</div>
                                    <div className=" flex justify-between items-center">
                                        <DialogClose>
                                            <Button
                                                variant="primary"
                                                className=" bg-indigo-500 text-white font-semibold rounded-[5px]"
                                            >
                                                Cancel
                                            </Button>
                                        </DialogClose>
                                        <DialogClose>
                                            <Button
                                                variant="primary"
                                                className=" bg-red-500 text-white font-semibold rounded-[5px]"
                                                onClick={() => deleteRentItem(index)}
                                            >
                                                Delete
                                            </Button>
                                        </DialogClose>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                ))}
                {rent.rentComplete.length > 0 && (
                    <div className="flex justify-between items-center bg-[#ECEDF9] p-3">
                        <div></div>
                        <div className="font-medium">Total: {convertPriceToIndianNumberingSystem(totalAmount)}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default RentPayment;
import React, { useEffect } from "react";
import AddTeammates from "../components/customUi/add-teammates";
import { HOC } from "../components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import AddDrawer from "../components/customUi/add-drawer";
import { announcementSvg, bookingSvg, inquiryRecordSvg, materialSvg, taskSvg, todayPaymentSvg } from "../svgs/svgs";
import { useSelector } from "react-redux";
import { getTokenPermission } from "../firebase";

function WorkspacePage({ isAdmin }) {
  const workspace = useSelector((state) => state.teamReducer.team);
  const user = useSelector((state) => state.userReducer.user);

  const navigate = useNavigate();
  useEffect(() => {
    getTokenPermission();
  }, []);

  const channelsList = [
    {
      icon: announcementSvg,
      name: "Announcement",
      href: "/announcement",
    },
    {
      icon: taskSvg,
      name: "Tasks",
      href: "/taskteam",
    },
    {
      icon: materialSvg,
      name: "Material",
      href: "/material",
    },
    {
      icon: inquiryRecordSvg,
      name: "Inquiry-record",
      href: "/inquiry-record",
    },
    {
      icon: bookingSvg,
      name: "Booking",
      href: "/booking",
    },
  ];

  return (
    <div className=" max-h-[100vh] select-none h-[100vh]">
      <div className="px-3 h-[8vh] flex justify-between items-center border-b border-[#e7eoec]">
        <div className="flex justify-center items-center text-xl font-semibold">
          <div className=" flex items-center">
            <div className=" text-2xl font-[550] select-none place-items-center capitalize">{workspace?.teamName}</div>
          </div>
        </div>
        <div className="flex items-center gap-x-6">
          {user?.role === "admin" && <div onClick={() => navigate("/today-payment")}>{todayPaymentSvg}</div>}
          <div onClick={() => {
            navigate("/profile")
          }} className=" h-[28px] w-[28px] ">
            <img src={user.imageUrl} className="h-full w-full rounded-[8px]" alt="" />
          </div>
        </div>
      </div>
      <div className="h-[82vh] relative">
        <div className=" px-3 pt-3">
          <div className=" text-xl mb-3 font-[600]">Channels</div>
          <div className="flex flex-col">
            {isAdmin ? (
              <>
                {channelsList.map((channel, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setTimeout(() => {
                        navigate(channel.href)
                      }, 200);
                    }}
                    className="p-[12px] select-none rounded-[8px] text-[#1d1b20] sc mb-[16px] w-full lg:w-[400px] flex justify-between items-center cursor-pointer relative"
                  >
                    <div className=" flex justify-center items-center gap-x-4">
                      <div>{channel.icon}</div>
                      <div className=" text-xl font-medium">
                        {channel.name}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {channelsList.map((channel, index) => (
                  <div
                    onClick={() => navigate(channel.href)}
                    className={`${(user?.role === "sitemanager" && channel.name === "Inquiry-record") && "hidden"} ${(user?.booking === "none" && channel.name === "Booking") && "hidden"} p-3 mb-2 sc select-none text-[#1d1b20] w-full lg:w-[400px] flex justify-between cursor-pointer rounded-[8px]`}
                    key={index}
                  >
                    <div className=" flex justify-center items-center gap-x-4">
                      <div>{channel.icon}</div>
                      <div className=" font-medium text-xl">
                        {channel.name}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className=" border-b border-[#e7eoec] my-3"></div>
        {isAdmin && (
          <div>
            <AddTeammates textColor={"text-black"} color={"bg-[#F2F3FF]"} />
          </div>
        )}
        <div className={`${""} fixed bottom-44 lg:bottom-52 right-20 lg:right-[42.5%]`}>
          <AddDrawer isAdmin={isAdmin} />
        </div>
      </div>
    </div>
  );
}

export default HOC(WorkspacePage);
import axios from "axios"
import { baseUrl } from "../../lib/base-url"
import { GET_TEAM } from "../types/types"

export const getTeam = (teamId) => {
    return (dispatch) => {
        axios.get(`${baseUrl}/api/team/${teamId}`)
        .then((res) => {
            dispatch({ type: GET_TEAM, team: res.data});
        })
        .catch((err) => {
            console.log(err);
        })
    }
}

export const createTeam = (team) => {
    return (dispatch) => {
        axios.post(`${baseUrl}/api/team`, team)
        .then((res) => {
        })
        .catch((err) => {
            // handle Error
        })
    }
}
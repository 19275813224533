import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "../ui/drawer";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdOutlineDone } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteMaterial, updateMaterial } from "../../redux/actions/materialAction";
import { Button } from "../ui/button";

function MaterialComp({
  name,
  material,
  quantity,
  project,
  date,
  status,
  imageUrl,
  id,
  teamId,
  quantityType,
  setisLoading,
  role
}) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState({
    isOrdered: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  let longPressTimeout;
  const drawerRef = useRef(null);

  function formatDate(timestamp) {
    // Convert timestamp to Date object
    const date = new Date(timestamp);

    // Format the date components
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const month = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
    const year = date.getFullYear();
    let hours = date.getHours();
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

    // Construct the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${period}`;

    return formattedDate;
  }

  const handleChange = (value) => {
    const updatedOrder = { ...order, isOrdered: value === "Ordered" ? true : false }
    setOrder(updatedOrder);
    dispatch(updateMaterial(updatedOrder, id, teamId, setisLoading))
  }

  const handleDelete = (id, teamId) => {
    dispatch(deleteMaterial(id, teamId));
  }

  const handleTouchStart = (event) => {
    // Check if the touch event target is within the Select component
    if (!event.target.closest('.select')) {
      longPressTimeout = setTimeout(() => {
        setIsOpen(true);
      }, 500); // Adjust the time as needed
    }
  }

  const handleTouchEnd = () => {
    clearTimeout(longPressTimeout);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className="p-2 px-3 select-none" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <div className=" flex justify-start items-center mb-2 gap-x-3">
          <img
            src={imageUrl}
            className="h-[40px] w-[40px] object-cover"
            style={{ borderRadius: "50%" }}
            alt=""
          />
          <div className="text-[16px] font-medium">{name}</div>
          <div className=" text-[12px] text-gray-500 pt-1">{formatDate(date)}</div>
        </div>
        <div
          className="sc mt-1 mb-2 p-[16px]"
          style={{ borderRadius: "5px" }}
        >
          <div className=" flex justify-between">
            <div className=" text-lg font-[500]">{material}</div>
            <div className=" font-[500] text-lg">{quantity} {quantityType}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="pt-3 font-[500] text-[16px]">
              P: {project}
            </div>
            <div className="">
              <Select
                value={status ? "Ordered" : "Not ordered"}
                onValueChange={handleChange}
                className="select"
              >
                <SelectTrigger
                  className={`w-auto h-auto ${role === "sitemanager" && " pointer-events-none"} px-2 mt-3 ${status ? 'bg-[#28A745]' : 'bg-[#6C757D]'} h-[30px] text-white shadow-none border-0 text-[16px] rounded-[5px]`}
                >
                  {status !== "Ordered" ? <MdOutlinePendingActions className="me-[4px]" size={20} /> : <MdOutlineDone className="me-[4px]" size={16} />}
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="" style={{ borderRadius: "5px" }}>
                  <SelectItem className="select" value="Ordered">
                    Ordered
                  </SelectItem>
                  <SelectItem className="select" value="Not ordered">
                    Not ordered
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} ref={drawerRef}>
        <DrawerContent className="pb-4 px-3">
          <DrawerClose onClick={() => setIsOpen(false)}>
            <Button
              variant="primary"
              type="button"
              onClick={() => handleDelete(id, teamId)}
              className="border-[1px] mt-5 rounded-[8px] border-[red] text-[red] h-[50px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-medium"
            >
              Delete material
            </Button>
          </DrawerClose>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MaterialComp;

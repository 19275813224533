import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../ui/dialog";
import { pencilFill } from "../../svgs/svgs";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";

function EditProfileDrawer() {

  const user = useSelector((state) => state.userReducer.user);
  const [obj, setObj] = useState({
    userName: ""
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setObj({ ...obj, userName: user?.userName})
    // eslint-disable-next-line
  }, [user]);


  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateUser(user._id, obj, user.userId, user?.teamId));
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setObj({ ...obj, [name]: value });
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger className="p-2 rounded-full active:bg-blue-100">
          {pencilFill}
        </DialogTrigger>
        <DialogContent
          className=" top-64"
        >
          <form onSubmit={handleSubmit}>
            <div className=" text-start">
              <label htmlFor="" className=" font-semibold mb-3 text-start">
                Change your name
              </label>
              <input
                type="text"
                className="w-[100%] sc outline-none h-[50px] px-2"
                placeholder="Change Name"
                style={{ borderRadius: "8px" }}
                autoFocus
                value={obj.userName}
                onChange={handleChange}
                name="userName"
              />
              <div className=" gap-x-5 mt-3 flex justify-end items-center text-[#003C9A]">
                <DialogClose>
                  <button type="submit">Save</button>
                </DialogClose>
                <DialogClose>
                  <button onClick={() => setObj({ ...obj, userName: user?.userName})} type="button">Cancel</button>
                </DialogClose>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditProfileDrawer;

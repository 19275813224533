import { GETANNOUNCEMENT } from "../types/types"

const defaultState = {
    announcement: [],
}

export const announcementReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GETANNOUNCEMENT:
            return {
                ...state, announcement: action.data,
            }
        default:
            return {
                ...state
            }
    }
}
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { routeArr } from './routes/routesList';
import { SignIn, SignUp, SignedIn, SignedOut, useUser } from '@clerk/clerk-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getTeam } from './redux/actions/teamAction';
import { getAllUserFromTeam, getUser } from './redux/actions/userAction';
import { getTaskByTeamId, getTaskByUserName } from './redux/actions/taskAction';
import { getProjectByTeamId } from './redux/actions/projectAction';
import LoaderComp from './components/Loader';
import { requestPermission } from './hooks/requestPermission';
import axios from 'axios';
import { baseUrl } from './lib/base-url';
import Tab from './components/Tab';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  const dispatch = useDispatch();
  const { user } = useUser();
  const User = useSelector((state) => state.userReducer.user);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    setIsAdmin(User?.role === "admin");
  }, [User?.role]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const token = await requestPermission();
      axios.put(`${baseUrl}/api/user/${User._id}`, { user: { fcmToken: token } })
        .then((res) => {
        })
        .catch(() => {
          // handle Error
        })
    }

    getToken();
    // eslint-disable-next-line
  }, [User._id]);

  useEffect(() => {
    dispatch(getUser(user?.id, setisLoading));
    dispatch(getTeam(User?.teamId, setisLoading));
    dispatch(getAllUserFromTeam(User?.teamId));
    dispatch(getProjectByTeamId(User?.teamId));
    dispatch(getTaskByUserName(User?.email, User?.teamId));
  }, [dispatch, user?.id, User.userName, User.teamId, User?.email]);

  // useEffect(() => {
  //   const fetchData = () => {
  //     dispatch(getTeam(User?.teamId));
  //     dispatch(getUser(user?.id));
  //     dispatch(getTaskByUserName(User?.email, User?.teamId));
  //     dispatch(getAllUserFromTeam(User?.teamId));
  //     dispatch(getTaskByTeamId(User?.teamId));
  //     // dispatch(getProjectByTeamId(User?.teamId));
  //     // dispatch(getMaterial(User?.teamId));
  //     // dispatch(getInquiry(User?.teamId));
  //     // dispatch(getAnnouncementByTeamId(User?.teamId));
  //   };

  //   const interval = setInterval(fetchData, 30000);
  //   // Cleanup function to clear the interval when the component unmounts or when dependencies change
  //   return () => clearInterval(interval);
  // }, [dispatch, user?.id, User.email, User.teamId]);

  return (
    <div className='lg:max-w-[500px] lg:shadow mx-auto'>
      <SignedOut>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={
              <div className='h-[100vh] flex justify-center items-center'>
                <SignIn signUpUrl='/sign-up' afterSignInUrl={"/"} />
              </div>
            } />
            <Route path='/sign-up' element={
              <div className='h-[100vh] flex justify-center items-center'>
                <SignUp signInUrl='/' afterSignUpUrl={"/"} />
              </div>
            } />
            <Route />
          </Routes>
        </BrowserRouter>
      </SignedOut>
      <SignedIn>
        <BrowserRouter>
          <LoaderComp isLoading={isLoading} />
          <Routes>
            {
              routeArr.map((route) => (
                <Route key={route.href} path={route.href} element={<route.page isLoading={isLoading} setisLoading={setisLoading} />} />
              ))
            }
          </Routes>
          <Tab isAdmin={isAdmin} />
        </BrowserRouter>
      </SignedIn>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
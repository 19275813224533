import React, { useEffect } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { getInquiryById } from '../redux/actions/inquiryAction';
import { contactSvg, whatsappSvg } from '../svgs/svgs';
import DeleteInquiry from '../components/customUi/delete-inquiry-dialog';
import { EditInquiryDialog } from '../components/customUi/edit-inquiry-dialog';

function InquiryDetails() {
    const navigate = useNavigate();
    const { series, id } = useParams();
    const inquiry = useSelector((state) => state.inquiryReducer.inquiryObj);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInquiryById(id));
    }, [id, dispatch]);

    function convertTimeToCustomFormat(timeString) {
        // Convert provided time string to a Date object
        const date = new Date(timeString);

        // Define months array
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        // Format the date in the desired format
        const formattedDate = `Added on ${months[month]}, ${day}, ${year}`;

        return formattedDate;
    }

    const handlePhoneIconClick = (contact) => {
        if (contact) {
            window.location.href = `tel:${contact}`;
        }
    };

    const handleWhatsappIconClick = (contact) => {
        if (contact) {
            // Assuming contact is a valid WhatsApp number
            const whatsappLink = `https://wa.me/${contact.replace(/\s/g, "")}`;
            window.location.href = whatsappLink;
        }
    };

    return (
        <div className='h-[100vh] relative'>
            <div className=" h-[8%] flex justify-between items-center border-b ps-1 pe-3 border-[#e7eoec]">
                <div className=" flex justify-between items-center gap-x-1">
                    <div onClick={() => navigate("/inquiry-record")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">Inquiry #{series}</div>
                </div>
                <div className=''>
                    <EditInquiryDialog inquiryObj={inquiry} />
                </div>
            </div>
            <div className='h-[84%] overflow-y-auto px-3'>
                <div className='border-b py-3 border-[#DEE2F4]'>
                    <div className='text-[18px]'>Name</div>
                    <div className='text-xl font-semibold'>{inquiry?.customerName}</div>
                </div>
                <div className='border-b flex items-center justify-between py-3 border-[#DEE2F4]'>
                    <div>
                        <div className='text-[18px]'>Mobile No.</div>
                        <div className='text-xl font-semibold'>{inquiry?.contact}</div>
                    </div>
                    <div className='flex items-center gap-x-3'>
                        <div onClick={() => handlePhoneIconClick(inquiry?.contact)} className='bg-black rounded-full p-[8px]'>{contactSvg}</div>
                        <div onClick={() => handleWhatsappIconClick(inquiry?.contact)}>{whatsappSvg}</div>
                    </div>
                </div>
                {!(inquiry?.brokerNumber === undefined || inquiry?.brokerNumber === "") && (
                    <div className='border-b flex items-center justify-between py-3 border-[#DEE2F4]'>
                        <div>
                            <div className='text-[18px]'>Dalal/Broker Number.</div>
                            <div className='text-xl font-semibold'>{inquiry?.brokerNumber}</div>
                        </div>
                        <div className='flex items-center gap-x-3'>
                            <div onClick={() => handlePhoneIconClick(inquiry?.brokerNumber)} className='bg-black rounded-full p-[8px]'>{contactSvg}</div>
                            <div onClick={() => handleWhatsappIconClick(inquiry?.brokerNumber)}>{whatsappSvg}</div>
                        </div>
                    </div>
                )}
                <div className='border-b py-3 border-[#DEE2F4]'>
                    <div className='text-[18px]'>Inquired project</div>
                    <div className='text-xl font-semibold'>{inquiry?.project}</div>
                </div>
                {!(inquiry?.createdBy === undefined || inquiry?.createdBy === "") && (
                    <div className='border-b flex items-center py-3 border-[#DEE2F4]'>
                        <div>
                            <div className='text-[18px]'>Added By</div>
                            <div className='text-xl font-semibold'>{inquiry?.createdBy}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className=' absolute items-center border-t border-[#DEE2F4] px-3 w-full flex justify-between bottom-0 h-[8%]'>
                <div>
                    {convertTimeToCustomFormat(inquiry?.createdAt)}
                </div>
                <div>
                    <DeleteInquiry id={id} />
                </div>
            </div>
        </div>
    )
}

export default InquiryDetails

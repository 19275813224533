import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GET_MATERIAL_BYTEAMID } from "../types/types";
import toast from "react-hot-toast";

export const getMaterial = (teamId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/materialOrder/getMaterialByTeamId/${teamId}`)
      .then((res) => {
        dispatch({ type: GET_MATERIAL_BYTEAMID, material: res.data });
        setisLoading(false);
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const addMaterial = (material, teamId, setisLoading) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/materialOrder`, material)
      .then(() => {
        dispatch(getMaterial(teamId, setisLoading));
        toast.success("Material added");
      })
      .catch(() => {
        // handle Error
        toast.error("Something went wrong");
      });
  };
};

export const updateMaterial = (materialOrder, id, teamId, setisLoading) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/materialOrder/${id}`, { materialOrder })
      .then(() => {
        dispatch(getMaterial(teamId, setisLoading));
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const deleteMaterial = (id, teamId) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl}/api/materialOrder/${id}`)
      .then(() => {
        dispatch(getMaterial(teamId));
        toast.success("Material deleted");
      })
      .catch((err) => {
        toast.success("Something went wrong");
        // HANDLE ERROR
      });
  };
};

import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { pencilSvg } from "../../svgs/svgs";
import { editInquiry } from "../../redux/actions/inquiryAction";

export const EditInquiryDialog = ({ inquiryObj }) => {
    const projects = useSelector((state) => state.projectReducer.projects);
    const user = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    let [inquiry, setInquiry] = useState({
        customerName: inquiryObj?.customerName,
        contact: inquiryObj?.contact,
        project: inquiryObj?.project,
        teamId: inquiryObj?.teamId,
        brokerNumber: inquiryObj?.brokerNumber,
    });

    useEffect(() => {
        setInquiry({
            ...inquiry,
            customerName: inquiryObj?.customerName,
            contact: inquiryObj?.contact,
            project: inquiryObj?.project,
            teamId: inquiryObj?.teamId,
            brokerNumber: inquiryObj?.brokerNumber,
        })
    }, [inquiryObj]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        inquiry = { ...inquiry, [name]: value };
        setInquiry({ ...inquiry });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editInquiry(inquiryObj._id, inquiry, inquiryObj.teamId));
    }

    return (
        <Dialog>
            <DialogTrigger>
                {pencilSvg}
            </DialogTrigger>
            <DialogContent className="w-[95vw] top-72 rounded-[8px]">
                <form onSubmit={handleSubmit}>
                    <div className=" w-full mt-2">
                        <div className="w-full">
                            <label className="mb-1">Customer Name*</label>
                            <input
                                type="text"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="Jaydeep savaliya"
                                style={{ borderRadius: "5px" }}
                                name="customerName"
                                step={"any"}
                                onChange={handleChange}
                                value={inquiry.customerName}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label className="mb-1">Mo. Number*</label>
                            <input
                                type="number"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="9099997299"
                                style={{ borderRadius: "5px" }}
                                name="contact"
                                step={"any"}
                                onChange={handleChange}
                                value={inquiry.contact}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label className="mb-1">Dalal/Broker Number*</label>
                            <input
                                type="number"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="9099997299"
                                style={{ borderRadius: "5px" }}
                                name="brokerNumber"
                                step={"any"}
                                onChange={handleChange}
                                value={inquiry?.brokerNumber}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <div className="mb-1">Inquiry Project*</div>
                            <Select
                                className="sc"
                                defaultValue={inquiry?.project}
                                onValueChange={(value) => {
                                    inquiry = { ...inquiry, project: value };
                                    setInquiry({ ...inquiry });
                                }}
                            >
                                <SelectTrigger
                                    className="w-full sc px-3 h-[50px] shadow-none border-0 text-lg rounded-[5px]"
                                >
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="rounded-[5px]">
                                    {
                                        projects.map((project) => (
                                            <SelectItem key={project._id} className="select" value={project.projectName}>
                                                {project.projectName}
                                            </SelectItem>
                                        ))
                                    }
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <DialogClose className="w-full mt-3">
                        <Button
                            type="submit"
                            variant={"primary"}
                            className="flex active:bg-opacity-70 gap-x-2 mt-3 text-[18px] h-[50px] py-[24px] text-white justify-center items-center plus w-full font-[500] rounded-[8px]"
                        >
                            Update
                        </Button>
                    </DialogClose>
                    <DialogClose className="w-full">
                        <Button
                            variant="primary"
                            type="button"
                            className=" border-[1px] mt-3 discard h-[48px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500] rounded-[8px]"
                        >
                            Discard
                        </Button>
                    </DialogClose>
                </form>
            </DialogContent>
        </Dialog>
    )
}

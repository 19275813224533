import { GET_MATERIAL_BYTEAMID } from "../types/types"

const defaultState = {
    material: [],
}

export const materialReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_MATERIAL_BYTEAMID:
            return {
                ...state, material: action.material
            }
        default:
            return {
                ...state
            }
    }
}
import { Plus } from "lucide-react";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addInquiry } from "../../redux/actions/inquiryAction";

export const AddInquiryDialog = ({ currentDate }) => {
    const projects = useSelector((state) => state.projectReducer.projects);
    const user = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    let [inquiry, setInquiry] = useState({
        customerName: "",
        contact: "",
        project: "",
        createdAt: "",
        teamId: "",
    });

    useEffect(() => {
        inquiry = {...inquiry, project: projects[0]?.projectName, createdAt: currentDate, teamId: user?.teamId, createdBy: user?.userName};
        setInquiry({...inquiry });
    }, [projects, currentDate, user?.teamId, user?.userName]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        inquiry = {...inquiry, [name]: value };
        setInquiry({...inquiry });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addInquiry(inquiry, user?.teamId));
    }

    return (
        <Dialog>
            <DialogTrigger>
                <button
                    className="h-[62px] w-[62px] flex justify-center
             items-center plus absolute active:scale-[0.9] transition-all"
                    style={{ borderRadius: "16px" }}
                >
                    <Plus size={34} color="#fff" />
                </button>
            </DialogTrigger>
            <DialogContent className="w-[95vw] top-72 rounded-[8px]">
                <form onSubmit={handleSubmit}>
                    <div className=" w-full mt-2">
                        <div className="w-full">
                            <label className="mb-1">Customer Name*</label>
                            <input
                                type="text"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="Jaydeep savaliya"
                                style={{ borderRadius: "5px" }}
                                name="customerName"
                                step={"any"}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label className="mb-1">Mo. Number*</label>
                            <input
                                type="number"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="9099997299"
                                style={{ borderRadius: "5px" }}
                                name="contact"
                                step={"any"}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <label className="mb-1">Dalal/Broker Number</label>
                            <input
                                type="number"
                                className="w-full sc px-3 outline-none h-[50px]"
                                placeholder="9099997299"
                                style={{ borderRadius: "5px" }}
                                name="brokerNumber"
                                step={"any"}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <div className="mb-1">Inquiry Project*</div>
                            <Select
                                className="sc"
                                defaultValue={projects[0]?.projectName}
                                onValueChange={(value) => {
                                    inquiry = { ...inquiry, project: value };
                                    setInquiry({ ...inquiry });
                                }}
                            >
                                <SelectTrigger
                                    className="w-full sc px-3 h-[50px] shadow-none border-0 text-lg rounded-[5px]"
                                >
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="rounded-[5px]">
                                    {
                                        projects.map((project) => (
                                            <SelectItem key={project._id} className="select" value={project.projectName}>
                                                {project.projectName}
                                            </SelectItem>
                                        ))
                                    }
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <DialogClose className="w-full mt-3">
                        <Button
                            type="submit"
                            variant={"primary"}
                            className="flex active:bg-opacity-70 gap-x-2 mt-3 text-[18px] h-[50px] py-[24px] text-white justify-center items-center plus w-full font-[500] rounded-[8px]"
                        >
                            Create
                        </Button>
                    </DialogClose>
                    <DialogClose className="w-full">
                        <Button
                            variant="primary"
                            type="button"
                            className=" border-[1px] mt-3 discard h-[48px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500] rounded-[8px]"
                        >
                            Discard
                        </Button>
                    </DialogClose>
                </form>
            </DialogContent>
        </Dialog>
    )
}
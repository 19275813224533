import React, { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../ui/dialog";
import { Plus } from "lucide-react";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { addTask } from "../../redux/actions/taskAction";

function AssignTaskDialog({ userName, teamId, setisLoading, currentDate, assignTo, length }) {
  const [isDisable, setIsDisable] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  let [task, setTask] = useState({
    taskTitle: "",
    assignTo: assignTo,
    teamId: "",
    imageUrl: "",
    createdAt: "",
    createdBy: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length < 1) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
    task = { ...task, [name]: value };
    setTask({ ...task });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    task = { ...task, teamId: teamId, createdAt: currentDate, createdBy: user?.email, assignTo: assignTo, series: length + 1 };
    setTask({ ...task });
    dispatch(addTask(task, userName, teamId, setisLoading, assignTo));
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <button
            className="h-[62px] w-[62px] flex justify-center
             items-center plus absolute active:scale-[0.9] transition-all"
            style={{ borderRadius: "16px" }}
          >
            <Plus size={34} color="#fff" />
          </button>
        </DialogTrigger>
        <DialogContent
          className=" top-48 w-[95vw] fixed"
          style={{ borderRadius: "10px" }}
        >
          <form onSubmit={handleSubmit}>
            <div>
              <label className="mb-1">Task*</label>
              <input
                type="text"
                className="w-full sc ps-[16px] outline-none h-[50px]  text-start"
                placeholder="Enter Task"
                style={{ borderRadius: "5px" }}
                autoFocus={false}
                name="taskTitle"
                onChange={handleChange}
              />
            </div>
            <DialogClose disabled={isDisable} className="w-full">
              <Button
                disabled={isDisable}
                type="submit"
                variant={"primary"}
                className="flex active:bg-opacity-70 plus gap-x-2 h-[50px] text-[18px] mt-4 text-white justify-center items-center w-full font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Create
              </Button>
            </DialogClose>
            <DialogClose className="w-full">
              <Button
                variant="primary"
                type="button"
                className="border-[1px] mt-3 discard h-[50px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500]"
                style={{ borderRadius: "8px" }}
              >
                Discard
              </Button>
            </DialogClose>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default AssignTaskDialog;
import { BiArrowBack } from "react-icons/bi";
import { manageAccessSvg, removeAccessSvg } from "../svgs/svgs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { updateUserByEmail } from "../redux/actions/userAction";
import toast from "react-hot-toast";

const ManageAccess = ({ setisLoading }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const team = useSelector((state) => state.userReducer.teamUser);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const dispatch = useDispatch();
    const User = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        // Filter users based on the condition
        if (team) {
            const filtered = team.filter(user => {
                if (user.booking === "all") {
                    return true;
                } else {
                    const ids = user.booking.split(',').map(item => item.trim());
                    return ids.includes(id);
                }
            });
            setFilteredUsers(filtered);
        }
    }, [id, team]);

    const removeAccess = (user) => {
        setisLoading(true);
        axios.get(`${baseUrl}/api/user/${user.userId}`)
            .then((res) => {
                // Split the booking string by commas
                const bookings = user.booking.split(',').map(item => item.trim());
                // Filter out the id to be removed
                const updatedBookings = bookings.filter(booking => booking !== id);
                // Join the remaining ids back into a comma-separated string
                const updatedBookingString = updatedBookings.join(',');
                dispatch(updateUserByEmail(user.email, { booking: updatedBookingString }, user.userId, "Access Removed", setisLoading));
            })
            .catch(() => {
                // handle Error
            });
    }

    return (
        <div className="h-[100vh] overflow-hidden">
            <div className=" h-[8%] flex justify-between items-center px-1 border-b border-[#e7eoec]">
                <div className=" flex py-2 px-3 justify-between items-center w-full">
                    <div className=" flex justify-start items-center gap-x-3">
                        <div className=" rounded-full flex justify-center items-center">
                            <BiArrowBack
                                onClick={() => {
                                    if (id === "660023ebb88125e485ba5237" || id === "6601729035c1f6e9f7782308") {
                                        navigate(`/rent/${id}`);
                                    }
                                    else {
                                        navigate(`/wingproject/${id}`);
                                    }
                                }} size={24} />
                        </div>
                        <div className=" text-xl font-semibold cursor-pointer">
                            Manage access
                        </div>
                    </div>
                    <div className='flex items-center gap-x-3'>
                        <div onClick={() => navigate(`/addaccess/${id}`)}>{manageAccessSvg}</div>
                    </div>
                </div>
            </div>
            <div className="h-[92%] p-3 overflow-auto">
                <div className="text-[18px] mb-[24px] font-medium">People with access</div>
                {filteredUsers.map((user, index) => (
                    <div key={index} className="flex justify-between items-center mb-[24px]">
                        <div className="flex gap-x-3 items-center">
                            <img src={user.imageUrl} className="h-[28px] w-[28px] rounded-full" alt="" />
                            <div className="capitalize">
                                <div className="text-xl">{user.userName}</div>
                                <div className="text-[14px]">{user.role}</div>
                            </div>
                        </div>
                        {User?.booking === "all" && (
                            <>
                                {user?.booking !== "all" && <div onClick={() => removeAccess(user)}>{removeAccessSvg}</div>}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ManageAccess;
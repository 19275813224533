import { useUser } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuthenticatedUser } from "../hooks/authenticated-user";
import { useDispatch, useSelector } from "react-redux";
import { createTeam } from "../redux/actions/teamAction";
import { Loader2 } from "lucide-react";
import { updateUserByEmail } from "../redux/actions/userAction";
import { v4 as uuidv4 } from 'uuid';

function CreateWorkspace() {
  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress.emailAddress;
  const User = useSelector((state) => state.userReducer.user);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  
  let [obj, setObj] = useState({
    teamName: "",
    createdBy: userEmail,
    teamId: uuidv4(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.teamReducer.team);
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(User?.email)
    setUserId(User?.userId)
  }, [User?.email, User?.userId]);

  useEffect(() => {
    if (workspace?.teamName) {
      navigate("/workspace");
    }
    setIsMainLoading(false);
  }, [navigate, workspace?.teamName]);

  const isAuthenticated = checkAuthenticatedUser(userEmail);


  const handleChange = (e) => {
    const { name, value } = e.target;
    obj = { ...obj, [name]: value }
    setObj({ ...obj });
  }

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    dispatch(updateUserByEmail(userEmail , { teamId: obj.teamId, role: "admin" }, user?.id));
    dispatch(createTeam(obj));
    setTimeout(() => {
      navigate("/workspace")
      setIsLoading(false);
    }, 300);
  }

  return (
    <>
      {
        isMainLoading ? (
          <div className="h-[100vh] w-full flex justify-center items-center">
            <Loader2 className=" animate-spin"/>
          </div>
        ) : (
          isAuthenticated ? (
            <div className=" pt-10 px-3">
              <div className=" font-[500] text-[20px] mb-1">Let&apos;s make workspace!</div>
              <div className=" font-[400] mb-4 text-[16px] text-[#49454F]">
                Step 1 of 1
              </div>
              <div className="text-[24px] font-[500]">
                Workspace or team name
              </div>
              <form onSubmit={handleSubmit} className="">
                <div>
                  <input
                    type="text"
                    className="my-3 px-3 lg:w-[300px] h-[60px] w-full sc outline-none"
                    style={{ borderRadius: "8px" }}
                    autoFocus
                    placeholder="Enter name"
                    name="teamName"
                    onChange={handleChange}
                  />
                  <button
                    disabled={obj.teamName === ""}
                    type="submit"
                    className=" bg-[#0066FA] w-full font-medium text-[19px] mt-2 text-white h-[60px] px-3 flex gap-x-1 justify-center items-center disabled:cursor-not-allowed"
                    style={{ borderRadius: "8px" }}
                  >
                    {isLoading ? <Loader2 className=" animate-spin" /> : "Finish"}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className=" text-red-500 p-5 text-center text-2xl">
              Not Allowed
            </div>
          )
        )
      }
    </>
  );
}

export default CreateWorkspace;

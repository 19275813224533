export const GET_TEAM = "GET_TEAM";
export const GET_USER = "GET_USER";
export const GET_TASK_BY_USERNAME = "GET_TASK_BY_USERNAME";
export const GET_USER_FROM_TEAM = "GET_USER_FROM_TEAM";
export const GET_TASK_BY_TEAMID = "GET_TASK_BY_TEAMID";
export const GET_PROJECT_BYTEAM = "GET_PROJECT_BYTEAM";
export const GET_PROJECT_BYID = "GET_PROJECT_BYID";
export const GET_MATERIAL_BYTEAMID = "GET_MATERIAL_BYTEAMID";
export const GET_FLAT_BY_PROJECTID = "GET_FLAT_BY_PROJECTID";
export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const GETINQUIRY = "GETINQUIRY";
export const GETINQUIRYBYID = "GETINQUIRYBYID";
export const GETANNOUNCEMENT = "GETANNOUNCEMENT";
export const GETPAYMENT = "GETPAYMENT";
export const GETRENT = "GETRENT";
import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { teamReducer } from "./teamReducer";
import { taskReducer } from "./taskReducer";
import { projectReducer } from "./projectReducer";
import { materialReducer } from "./materialReducer";
import { flatReducer } from "./flatReducer";
import { inquiryReducer } from "./inquiryReducer";
import { announcementReducer } from "./announcementReducer";
import { paymentReducer } from "./paymentReducer";
import { rentReducer } from "./rentReducer";

export const rootReducer = combineReducers({
    userReducer: userReducer,
    teamReducer: teamReducer,
    taskReducer: taskReducer,
    projectReducer: projectReducer,
    materialReducer: materialReducer,
    flatReducer: flatReducer,
    inquiryReducer: inquiryReducer,
    announcementReducer: announcementReducer,
    paymentReducer: paymentReducer,
    rentReducer: rentReducer,
});
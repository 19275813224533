import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select";
import { completePaymentSvg, completeSvg, dateSvg, incompletePaymentSvg } from "../svgs/svgs";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { useDispatch, useSelector } from "react-redux";
import { updateFlatById } from "../redux/actions/flatAction";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTrigger,
} from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { Plus } from "lucide-react";
import { addPayment, editPayment, getPayment } from "../redux/actions/paymentAction";

const PaymentDetails = ({ setisLoading }) => {
    const { id, projectId } = useParams();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [flat, setFlat] = useState();
    const dispatch = useDispatch();
    const paymentArr = useSelector((state) => state.paymentReducer.payment);
    const user = useSelector((state) => state.userReducer.user);
    let [payment, setPayment] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');

    useEffect(() => {
        dispatch(getPayment(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (id) {
            getFunction(id);
        }
    }, [id]);

    useEffect(() => {
        flat?.loanStatus === undefined && setProgress(0);
        flat?.loanStatus === "Application" && setProgress(1);
        flat?.loanStatus === "Process" && setProgress(2);
        flat?.loanStatus === "Sanctioned" && setProgress(3);
    }, [flat]);

    const getFunction = (id) => {
        axios.get(`${baseUrl}/api/flat/${id}`)
            .then((res) => setFlat(res.data))
            .catch((err) => { })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        payment = { ...payment, flatId: id, teamId: user?.teamId };
        setPayment({ ...payment });
        dispatch(addPayment(payment, id));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let timestamp = 0;
        if (name === 'dueDate') {
            const date = new Date(value);
            timestamp = date.getTime(); // Get timestamp in milliseconds
        } else {
            timestamp = value; // For other fields
        }
        payment = { ...payment, [name]: timestamp };
        setPayment({ ...payment });
    }

    function formatDate(milliseconds) {
        // Create a new Date object using the milliseconds
        const date = new Date(milliseconds);

        // Get day, month, and year from the Date object
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero indexed
        const year = date.getFullYear();

        // Pad single digit day or month with leading zero if necessary
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Return formatted date string in dd/mm/yyyy format
        return formattedDay + '/' + formattedMonth + '/' + year;
    }

    function convertPriceToIndianNumberingSystem(price) {
        // Split the price into an array of digits.
        const digits = price.toString().split("");

        // Reverse the array of digits.
        digits.reverse();

        // Add a comma after every three digits, starting from the right.
        for (let i = 3; i < digits.length; i += 3) {
            digits.splice(i, 0, ",");
        }

        // Join the array of digits back into a string.
        const indianPrice = digits.join("");
        const payment = indianPrice.split('').reverse().join('');

        // Return the Indian price.
        return payment;
    }

    return (
        <div className="h-[100vh] overflow-auto">
            <div className=" h-[8vh] flex justify-between items-center ps-1 pe-3">
                <div className=" flex justify-start items-center gap-x-1">
                    <div>
                        <div className="flex p-2 rounded-full justify-center items-center">
                            <BiArrowBack onClick={() => {
                                navigate(`/flat-details/${id}/${projectId}?redirect=${redirect}`)
                            }} size={24} />
                        </div>
                    </div>
                    <div className=" text-xl font-semibold cursor-pointer">Payment</div>
                </div>
            </div>
            <>
                <div className="flex px-3 justify-between items-center border-b py-3">
                    <div className="text-lg">Payment type</div>
                    <Select
                        value={flat?.paymentType === undefined ? "" : flat?.paymentType}
                        onValueChange={(value) => {
                            dispatch(updateFlatById(id, { paymentType: value }, projectId, setisLoading, getFunction))
                        }}
                    >
                        <SelectTrigger
                            className={`w-auto px-2 ${flat?.paymentType === undefined ? 'bg-[#E6E7F4] text-black' : 'bg-[#A940CB] text-white'} font-[400] h-[40px] rounded-[2px] shadow-none border-0`}
                        >
                            <SelectValue placeholder="Select payment type" />
                        </SelectTrigger>
                        <SelectContent className="rounded-[5px]">
                            <SelectItem className="select hidden" value={"Dummy"}>
                                Dummy
                            </SelectItem>
                            <SelectItem className="select" value={"Cash"}>
                                Cash
                            </SelectItem>
                            <SelectItem className="select" value={"Loan"}>
                                Loan
                            </SelectItem>
                            <SelectItem className="select" value={"Cash & Loan"}>
                                Cash & Loan
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                {(flat?.paymentType === "Loan" || flat?.paymentType === "Cash & Loan") && (
                    <>
                        <div className="flex px-3 justify-between items-center py-3">
                            <div className="text-lg">Loan status</div>
                            <Select
                                value={flat?.loanStatus === undefined ? "" : flat?.loanStatus}
                                onValueChange={(value) => {
                                    dispatch(updateFlatById(id, { loanStatus: value }, projectId, setisLoading, getFunction))
                                }}
                            >
                                <SelectTrigger
                                    className={`w-auto ${flat?.paymentType === undefined ? 'bg-[#E6E7F4] text-black' : 'bg-[#A940CB] text-white'} px-2 font-[400] h-[40px] rounded-[2px] shadow-none border-0`}
                                >
                                    <SelectValue placeholder="Select loan type" />
                                </SelectTrigger>
                                <SelectContent className="rounded-[5px]">
                                    <SelectItem className="select hidden" value={"Dummy"}>
                                        Dummy
                                    </SelectItem>
                                    <SelectItem className="select" value={"Application"}>
                                        Application
                                    </SelectItem>
                                    <SelectItem className="select" value={"Process"}>
                                        Process
                                    </SelectItem>
                                    <SelectItem className="select" value={"Sanctioned"}>
                                        Sanctioned
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </>
                )}
                {(progress > 0 && (flat?.paymentType === "Loan" || flat?.paymentType === "Cash & Loan")) && (
                    <div className="border-b pb-3">
                        <div className="flex mt-5 items-center justify-between rounded-lg px-8">
                            <div className="flex flex-col items-center space-x-1">
                                <div className="h-5 w-5 rounded-full mb-1">
                                    {progress === 0 &&
                                        <div className="w-5 h-5 border-2 border-[#3E4251] rounded-full"></div>
                                    }
                                    {progress > 0 && completeSvg}
                                </div>
                            </div>
                            <div className={`flex-1 h-[1px] ${progress > 1 ? "bg-[#4CAF50]" : "bg-[#3E4251]"} mx-2`}></div>
                            <div className="flex flex-col items-center space-x-1">
                                {progress > 1 ? completeSvg : (
                                    <div className="w-5 h-5 border-2 border-[#3E4251] rounded-full"></div>
                                )}
                            </div>
                            <div className={`flex-1 h-[1px] ${progress > 2 ? "bg-[#4CAF50]" : "bg-[#3E4251]"} mx-2`}></div>
                            <div className="flex flex-col items-center space-x-1">
                                {progress > 2 ? completeSvg : (
                                    <div className="w-5 h-5 border-2 border-[#3E4251] rounded-full"></div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-between rounded-lg p-2">
                            <div className="flex flex-col text-sm items-center space-x-1">
                                Application
                            </div>
                            <div className="flex flex-col text-sm items-center space-x-1">
                                Process
                            </div>
                            <div className="flex flex-col text-sm items-center space-x-1">
                                Sanctioned
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-3 px-3">
                    <div className="flex justify-between items-center">
                        <div className="text-xl font-semibold">Payment Tracking</div>
                        <Dialog>
                            <DialogTrigger>
                                <Button className="plus text-white gap-x-2 rounded-[5px] px-3">
                                    <Plus size={20} /> Add
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="w-[95vw] rounded-[8px] top-52">
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label className="mb-1">Amount*</label>
                                        <input
                                            type="number"
                                            className="w-full sc ps-[16px] rounded-[5px] outline-none h-[50px]  text-start"
                                            placeholder="10,00,000 Rs"
                                            name="amount"
                                            step={"any"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="paymentdate" className="mb-1">Due Date*</label>
                                        <div className="w-full bg-[#ecedf9] flex rounded-[5px] overflow-hidden h-[50px]">
                                            <input
                                                type="date"
                                                className="w-[80%] sc ps-[16px] pe-[16px] rounded-[5px] outline-none h-full text-start"
                                                placeholder="Add Date"
                                                name="dueDate"
                                                onChange={handleChange}
                                                id="paymentdate"
                                            />
                                            <label htmlFor="paymentdate" className="flex justify-center items-center w-[20%] h-full">{dateSvg}</label>
                                        </div>
                                    </div>
                                    <DialogClose className="w-full">
                                        <Button
                                            type="submit"
                                            variant={"primary"}
                                            className="flex active:bg-opacity-70 plus gap-x-2 h-[50px] text-[18px] mt-4 text-white justify-center items-center rounded-[8px] w-full font-[500]"
                                        >
                                            Add
                                        </Button>
                                    </DialogClose>
                                    <DialogClose className="w-full">
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="border-[1px] mt-3 discard h-[50px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500] rounded-[8px]"
                                        >
                                            Discard
                                        </Button>
                                    </DialogClose>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
                <div className="pt-3 mt-3">
                    <table className="w-full text-center">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Amount <span>(Rs.)</span></th>
                                <th>Due date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentArr?.map((payment, index) => {
                                return !payment.status && (
                                    <tr key={index} className={`${payment.status ? "bg-[#E6E7F4]" : ""}`}>
                                        <td className="flex justify-center items-center">
                                            <Dialog>
                                                <DialogTrigger>
                                                    {payment.status ? completePaymentSvg : incompletePaymentSvg}
                                                </DialogTrigger>
                                                <DialogContent
                                                    className=" text-center w-[95%]"
                                                    style={{ borderRadius: "5px" }}
                                                >
                                                    <div className=" text-xl font-semibold">Mark Payment as Complete</div>
                                                    <div className=" flex justify-between items-center">
                                                        <DialogClose>
                                                            <Button
                                                                variant="primary"
                                                                className=" bg-indigo-500 text-white font-semibold"
                                                                style={{ borderRadius: "5px" }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </DialogClose>
                                                        <DialogClose>
                                                            <Button
                                                                onClick={() => {
                                                                    dispatch(editPayment(payment._id, { status: !payment.status }, id))
                                                                }}
                                                                variant="primary"
                                                                className=" bg-green-500 text-white font-semibold"
                                                                style={{ borderRadius: "5px" }}
                                                            >
                                                                Complete
                                                            </Button>
                                                        </DialogClose>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                        </td>
                                        <td className="text-center">{convertPriceToIndianNumberingSystem(payment.amount)}</td>
                                        <td className="text-center">{formatDate(payment.dueDate)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        </div>
    );
}

export default PaymentDetails;
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../lib/base-url";
import { commentSvg } from "../svgs/svgs";

const ActivityPage = ({ setisLoading }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer.user);
    const [activity, setActivity] = useState([]);

    useEffect(() => {
        setisLoading(true);
        axios.get(`${baseUrl}/api/activity/${user?.teamId}`)
            .then((res) => {
                setActivity(res.data.slice().reverse())
            })
            .catch((err) => { })
            .finally(() => setisLoading(false))
    }, [user?.teamId]);

    function convertTimestamp(timestamp) {
        // Convert timestamp to milliseconds
        let date = new Date(timestamp);

        // Get today's date
        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to beginning of the day for comparison

        // Get the date difference in milliseconds
        let difference = today - date;

        // Convert milliseconds to days
        let daysDifference = Math.floor((difference / (1000 * 60 * 60 * 24)) + 1);

        // Set isRed based on whether the timestamp is today

        // Convert the date difference to human-readable format
        if (daysDifference === 0) {
            return "Today";
        } else if (daysDifference === 1) {
            return "1d";
        } else if (daysDifference === 2) {
            return "2d";
        } else if (daysDifference <= 10) {
            return daysDifference + "d";
        } else if (daysDifference <= 30) {
            return formatDate(date);
        } else if (daysDifference < 365) {
            let monthsDifference = Math.floor(daysDifference / 30);
            return monthsDifference + " m" + (monthsDifference > 1 ? "s" : "");
        } else {
            let yearsDifference = Math.floor(daysDifference / 365);
            return yearsDifference + " y" + (yearsDifference > 1 ? "s" : "");
        }
    }

    function formatDate(date) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return date.getDate() + " " + monthNames[date.getMonth()];
    }

    const updateActivity = (item) => {
        if (item?.isSeen !== true) {
            axios.put(`${baseUrl}/api/activity/${item._id}`, { isSeen: true })
                .then((res) => { })
                .catch((err) => { });
        }
    }

    return (
        <div className="h-[88vh] overflow-hidden">
            <div className=" h-[8vh] flex justify-between items-center px-3 border-b border-[#e7eoec]">
                <div className="flex justify-start items-center gap-x-1">
                    <div className=" text-xl font-semibold cursor-pointer">Activity</div>
                </div>
                <div onClick={() => {
                    setTimeout(() => {
                        navigate("/profile")
                    }, 0);
                }} className=" h-[28px] w-[28px] ">
                    <img src={user.imageUrl} className="h-full w-full rounded-[8px]" alt="" />
                </div>
            </div>
            <div className="h-[80vh] overflow-y-auto">
                {activity.map((item, index) => (
                    <div onClick={() => {
                        navigate(`${item?.redirect}?redirect=/activity`);
                        updateActivity(item);
                    }}
                        key={index} className={`px-3 py-3 border-b border-[#e7eoec] ${item?.isSeen === true && "opacity-75"}`}>
                        <div className="flex w-full text-[12px] justify-between items-start gap-x-3">
                            <div className="flex w-[82%] items-center gap-x-3 justify-start">
                                <div className="flex gap-x-2 items-start">{item?.message?.includes("commented") ? commentSvg : ""} {item?.userName} {item?.message}</div>
                            </div>
                            <div className="flex w-[18%] text-[10px] gap-x-1">
                                <div className="flex items-center justify-end gap-x-1">
                                    {convertTimestamp(item?.time)} {convertTimestamp(item?.time) !== "Today" && <div className={`h-[8px] w-[8px] rounded-full bg-[red] ${item?.isSeen === true && "hidden"}`}></div>}
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-3 gap-x-3">
                            <div>
                                <img src={item?.imageUrl} className="rounded-[8px] object-cover" height={"36px"} width={"36px"} alt="user" />
                            </div>
                            <div>{item?.messageMain}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ActivityPage;
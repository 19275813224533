import React from "react";
import ShowMember from "./show-member";

function TeammatesComp({ imageUrl, name, role, id, userId }) {
  return (
    <div
      className="py-[18px] ps-3 pe-1 flex justify-between items-center bg-[#ECEDF9] border-b border-[#DEE2F4]"
    >
      <div className=" flex justify-center items-center gap-x-3">
        <img
          src={imageUrl}
          className="h-[40px] w-[40px] object-cover"
          style={{ borderRadius: "50%" }}
          alt=""
        />
        <div>{name}</div>
      </div>
      <div>
        <ShowMember id={id} userId={userId} imageUrl={imageUrl} name={name} role={role}/>
      </div>
    </div>
  );
}

export default TeammatesComp;

import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import EditProfileDrawer from "../components/customUi/edit-profile-drawer";
import { useSelector } from "react-redux";

function ProfilePage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer.user);

  const formatDate = (timestamp) => {
    // Create a new Date object with the timestamp
    var date = new Date(timestamp);

    // Get the day, month, and year
    var day = date.getDate();
    var month = date.getMonth() + 1; // Month starts from 0, so add 1
    var year = date.getFullYear();

    // Format day and month to have leading zeros if needed
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    // Formatted date string in dd/mm/yyyy format
    var formattedDate = day + '/' + month + '/' + year;

    return formattedDate;
  }

  return (
    <div className="h-[100vh] max-h-[100vh]">
      <div className=" h-[8vh] flex justify-between items-center ps-1 pe-3 border-b border-zinc-200">
        <div className=" flex justify-start items-center gap-x-1">
          <div className=" rounded-full py-2 px-3">
            <BiArrowBack onClick={() => {
              setTimeout(() => {
                navigate("/workspace")
              }, 100);
            }} size={24} />
          </div>
          <div className=" text-xl font-semibold cursor-pointer">You</div>
        </div>
      </div>
      <div className="h-[92vh] px-3 pt-3">
        <div className=" relative">
          <img
            src={user.imageUrl}
            alt=""
            className="h-[380px]  object-cover"
            style={{ borderRadius: "8px" }}
          />

          {/* add later functionality of edit photo */}
          {/* <div
            className=" flex justify-center items-center absolute cursor-pointer bottom-5 left-[50%] translate-x-[-50%] w-[120px] text-center font-semibold h-[50px] text-white plus px-[14px]"
            style={{ borderRadius: "8px" }}
          >
            <div>Edit Photo</div>
          </div> */}
        </div>
        <div className="mt-2 flex justify-between items-center">
          <div className=" text-2xl font-[500]">{user.userName}</div>
          <div className="">
            <EditProfileDrawer />
          </div>
        </div>
        <div className="flex my-3 px-2 items-center gap-x-1">
          <GoDotFill size={20} color={user.isActive ? "#48E548": "red"} /> {user.isActive ? "Active" : "Offline"}
        </div>
        <div className=" text-[#49454F]">
          Joined team on {formatDate(user.joinedTimestamp)}
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;

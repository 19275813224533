import { GET_USER, GET_USER_FROM_TEAM } from "../types/types"

const defaultState = {
    user: {},
    teamUser: [],
}

export const userReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_USER:
            return {
                ...state, user: action.user,
            }
        case GET_USER_FROM_TEAM:
            return {
                ...state, teamUser: action.teamUser,
            }
        default:
            return {
                ...state
            }
    }
}
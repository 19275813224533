import * as React from "react";
import { Check } from "lucide-react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { cn } from "../../lib/utils";

const RadioGroup = React.forwardRef(function ({ className, ...props }, ref) {
  return React.createElement(RadioGroupPrimitive.Root, {
    className: cn("grid gap-2", className),
    ...props,
    ref: ref,
  });
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef(function (
  { className, ...props },
  ref
) {
  return React.createElement(
    RadioGroupPrimitive.Item,
    {
      ref: ref,
      className: cn(
        "aspect-square h-6 w-6 rounded-[3px] border-2 border-black/50 text-white focus:border-[#003C9A] focus:bg-[#003C9A] focus:outline-none data-[state=checked]:border-[#003C9A] data-[state=checked]:bg-[#003C9A] focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
        className
      ),
      ...props,
    },
    React.createElement(
      RadioGroupPrimitive.Indicator,
      { className: "flex items-center justify-center" },
      React.createElement(Check, { className: "h-4 w-4" })
    )
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };

import { GET_TEAM } from "../types/types"

const defaultState = {
    team: {},
}

export const teamReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_TEAM:
            return {
                ...state, team: action.team,
            }
        default:
            return {
                ...state
            }
    }
}
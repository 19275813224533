import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { updateUserByEmail } from "../../redux/actions/userAction";

function AddTeammates({
  color,
  textColor
}) {
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [userId, setUserid] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    setUserid(user?.userId);
  }, [user?.userId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateUserByEmail(email, { teamId: user?.teamId }, userId));
  };

  return (
    <div>
      <div className=" my-3"></div>
      <div className="px-3">
        <Dialog>
          <DialogTrigger className="w-full lg:w-[400px]">
            <div
              className={` p-[12px] ${color} ${textColor ? textColor : "text-[white]"} mb-2 w-full lg:w-[400px] flex items-center gap-x-4`}
              style={{ borderRadius: "12px" }}
            >
              <FaPlus size={20} />
              <div className=" font-medium text-xl">Add teammates</div>
            </div>
          </DialogTrigger>
          <DialogContent className=" top-56 w-[95vw]"
            style={{ borderRadius: "10px" }}>
            <DialogHeader>
              <DialogTitle className="text-start text-2xl">Add teammates</DialogTitle>
              <div className=" text-start text-black font-normal text-xl">
                Add memeber or teammates in workspace by their email.
              </div>
              <form className="my-4 text-start" onSubmit={handleSubmit}>
                <div className="">
                  <input
                    type="text"
                    className="w-full sc ps-[16px] outline-none h-[50px]  text-start"
                    placeholder="Enter email address"
                    style={{ borderRadius: "8px" }}
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autoFocus
                  />
                </div>
                <DialogClose
                  className=" w-full"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className=" plus text-md w-full text-white h-[50px] mt-4 px-3 py-3"
                    style={{ borderRadius: "8px" }}
                  >
                    Invite teammates
                  </Button>
                </DialogClose>
              </form>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default AddTeammates;

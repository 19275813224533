import { SendHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { announcementSvg } from '../svgs/svgs';
import { addAnnouncement, getAnnouncementByTeamId } from '../redux/actions/announcementAction';

function AnnouncementPage() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const user = useSelector((state) => state.userReducer.user);
    const announcement = useSelector((state) => state.announcementReducer.announcement);
    const messagesEndRef = useRef(null);
    const dispatch = useDispatch();
    let [message, setMessage] = useState({
        message: "",
        createdBy: "",
        imageUrl: "",
        createdAt: "",
        teamId: "",
    });

    useEffect(() => {
        if(user?.role === 'admin') {
            setIsAuthenticated(true);
        }
        dispatch(getAnnouncementByTeamId(user?.teamId));
    }, [user?.role, user?.teamId]);

    // Scroll to the bottom of the chat window when new message is added
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        message = { ...message, imageUrl: user?.imageUrl, createdBy: user?.userName, createdAt: Date.now(), teamId: user?.teamId};
        setMessage({ ...message });
        dispatch(addAnnouncement(message));
        setMessage({
            message: "",
        })
    }

    return (
        <div className='h-[100vh]'>
            <div className=" h-[8%] flex justify-between items-center ps-1 pe-3 border-b border-[#e7eoec]">
                <div className=" flex justify-start items-center gap-x-1">
                    <div onClick={() => navigate("/workspace")} className="flex p-2 rounded-full justify-center items-center">
                        <BiArrowBack size={24} />
                    </div>
                    <div className=" text-xl font-medium cursor-pointer">Announcement</div>
                </div>
            </div>
            <div className='h-[82%] px-3 flex flex-col-reverse overflow-y-auto'>
                <div className='py-3'>
                    {/* Render your messages here */}
                    <div className='h-[56px] w-[56px] rounded-full flex justify-center items-center bg-[#E6E7F4]'>
                        {announcementSvg}
                    </div>
                    <div className='text-[24px] mt-2 font-semibold'>Welcome to announcement!</div>
                    <p className='text-[#424655] mb-5'>This is start of the announcement channel.</p>
                    {announcement.map((ann, index) => (
                        <MessageComp key={index} message={ann.message} name={ann.createdBy} time={ann.createdAt} imageUrl={ann.imageUrl}/>
                    ))}
                    {/* Reference to scroll to bottom */}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <div className={`w-full h-[10%] flex items-center border-t border-[#DEE2F4] mb-3 px-3 bg-[#FAF8FF]`}>
                {isAuthenticated && (
                    <form onSubmit={handleSubmit} className='flex mb-2 items-center w-full gap-x-2'>
                        <input
                            type="text"
                            className='h-[50px] focus:outline-none px-3 rounded-full w-full'
                            placeholder='Send Message'
                            name='message'
                            value={message.message}
                            onChange={(e) => {
                                message = { ...message, message: e.target.value}
                                setMessage({ ...message });
                            }}
                        />
                        <button className='bg-[#0066FA] p-2 rounded-full' type='submit'><SendHorizontal color="white" /></button>
                    </form>
                )}
                {!isAuthenticated && (
                    <div className='text-[#424655] text-center w-full'>You are not allowed to write in this channel.</div>
                )}
            </div>
        </div>
    )
}

export default AnnouncementPage;

const MessageComp = ({ name, imageUrl, message, time }) => {
    function formatDate(timestamp) {
      const date = new Date(timestamp);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
  
      // Convert hours to 12-hour format
      hours = hours % 12 || 12;
  
      // Add leading zeros if necessary
      const formattedDay = day < 10 ? '0' + day : day;
      const formattedMonth = month < 10 ? '0' + month : month;
      const formattedHours = hours < 10 ? '0' + hours : hours;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  
      return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes} ${period}`;
    }

    return (
      <div className='mt-3'>
        <div className='flex gap-x-3'>
          <img src={imageUrl} className='h-8 w-8 rounded-full' alt="user" />
          <div>
            <div className='flex items-center gap-x-2'>
              <div className='font-medium text-lg'>{name}</div>
              <div className='text-[12px] text-[#424655]'>{formatDate(time)}</div>
            </div>
            <div className='text-lg'>
              {message}
            </div>
          </div>
        </div>
      </div>
    );
  };
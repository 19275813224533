import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GET_FLAT_BY_PROJECTID } from "../types/types";
import toast from "react-hot-toast";

export const addFlat = (flat, projectId, setisLoading) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/flat`, flat)
      .then(() => {
        toast.success("Flat added");
        dispatch(getFlatByProjectId(projectId, setisLoading));
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };
};

export const getFlatByProjectId = (projectId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/flat/getFlatsByProjectId/${projectId}`)
      .then((res) => {
        dispatch({ type: GET_FLAT_BY_PROJECTID, flats: res.data });
        setisLoading(false);
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const updateFlatById = (id, flat, projectId, setisLoading, getFunction, userName, imageUrl, messageMain, message, redirect) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/flat/${id}`, { flat, userName, imageUrl, messageMain, message, redirect })
      .then((res) => {
        dispatch(getFlatByProjectId(projectId, setisLoading));
        toast.success("Flat edited");
        getFunction(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteFlat = (id, projectId) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl}/api/flat/${id}`)
      .then((res) => {
        toast.success("Flat deleted");
        dispatch(getFlatByProjectId(projectId));
      })
      .catch(() => {
        toast.success("Something went wrong");
      });
  };
};
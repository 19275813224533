import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";

const Checkbox = React.forwardRef(function ({ className, ...props }, ref) {
  return React.createElement(
    CheckboxPrimitive.Root,
    {
      ref: ref,
      className: cn(
        "aspect-square h-4 w-4 rounded-[3px] border-2 border-black/50 text-white focus:outline-none data-[state=checked]:border-none data-[state=checked]:bg-[#0066FA] disabled:cursor-not-allowed disabled:opacity-50",
        className
      ),
      ...props,
    },
    React.createElement(
      CheckboxPrimitive.Indicator,
      {
        className: cn("flex items-center justify-center text-current"),
      },
      React.createElement(CheckIcon, { className: "h-4 w-4" })
    )
  );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

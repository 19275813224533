import { GET_TASK_BY_ID, GET_TASK_BY_TEAMID, GET_TASK_BY_USERNAME } from "../types/types"

const defaultState = {
    selfTask: [],
    assignTask: [],
    task: {},
}

export const taskReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_TASK_BY_USERNAME:
            return {
                ...state, selfTask: action.task,
            }
        case GET_TASK_BY_TEAMID:
            return {
                ...state, assignTask: action.assignTask,
            }
        case GET_TASK_BY_ID:
            return {
                ...state, task: action.task,
            }
        default:
            return {
                ...state
            }
    }
}
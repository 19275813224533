import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectById } from '../redux/actions/projectAction';
import { getFlatByProjectId } from '../redux/actions/flatAction';
import { BiArrowBack } from 'react-icons/bi';
import { notWingWiseSvg } from '../svgs/svgs';

function WingFlat({ setisLoading }) {
    // const user = useSelector((state) => state.userReducer.user);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const flats = useSelector((state) => state.flatReducer.flats);
    const project = useSelector((state) => state.projectReducer.project);
    const [wings, setWings] = useState(["Shops", "A", "B", "C", "D", "E"]);
    const team = useSelector((state) => state.userReducer.teamUser);
    const user = useSelector((state) => state.userReducer.user);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        // Filter users based on the condition
        if (team) {
            const filtered = team.filter(user => {
                if (user.booking === "all") {
                    return true;
                } else {
                    const ids = user.booking.split(',').map(item => item.trim());
                    return ids.includes(id);
                }
            });
            setFilteredUsers(filtered);
        }
    }, [id, team]);

    useEffect(() => {
        if (id) {
            id === "65e45f5376b3acef903de2a7" && setWings(project?.wings);
            id === "65fd3cb88a1c45622acabab6" && setWings(project?.wings);
            id === "65fd3ce7e0df549bccfa8a90" && setWings(project?.wings);
            id === "65fd3cd9656bc6f747437ae3" && setWings(project?.wings);
            id === "65fd3cf9760d39e4f881c99f" && setWings(project?.wings);
            id === "65fd3d77656bc6f747437aff" && setWings(project?.wings);
            id === "6646de08a48a8ab76a8a9161" && setWings(project?.wings);
        }
    }, [id, project]);

    useEffect(() => {
        dispatch(getProjectById(id));
    }, [dispatch, id]);

    const fetchData = () => {
        dispatch(getFlatByProjectId(id, setisLoading));
    };

    useEffect(() => {
        fetchData();

        const fetchDataInterval = setInterval(() => {
            fetchData();
        }, 60000);

        return () => clearInterval(fetchDataInterval);
        // eslint-disable-next-line
    }, [id, dispatch, setisLoading]);

    // Custom sorting function
    // const customSort = (a, b) => {
    //     const seriesA = a.series.toUpperCase();
    //     const seriesB = b.series.toUpperCase();

    //     // Function to extract letters from series string
    //     const extractLetters = (str) => str.match(/[A-Za-z]+/) ? str.match(/[A-Za-z]+/)[0] : '';

    //     // Function to extract numbers from series string
    //     const extractNumber = (str) => str.match(/\d+/) ? parseInt(str.match(/\d+/)[0]) : Infinity;

    //     // Extract letters and numbers from series strings
    //     const lettersA = extractLetters(seriesA);
    //     const lettersB = extractLetters(seriesB);
    //     const numA = extractNumber(seriesA);
    //     const numB = extractNumber(seriesB);

    //     // If letters are different, sort by letters
    //     if (lettersA !== lettersB) {
    //         return lettersA.localeCompare(lettersB);
    //     }

    //     // If letters are the same, sort by numbers
    //     if (numA !== numB) {
    //         return numA - numB;
    //     }

    //     // If both letters and numbers are the same, no change in order
    //     return 0;
    // };

    // Sort the flats array
    // const sortedFlats = [...flats].sort(customSort);
    return (
        <div className='h-[100vh]'>
            <div className=" h-[8%] flex justify-between items-center px-1 border-b border-[#e7eoec]">
                <div className=" flex py-2 px-3 justify-between items-center w-full">
                    <div className=" flex justify-start items-center gap-x-3">
                        <div className=" rounded-full flex justify-center items-center">
                            <BiArrowBack
                                onClick={() => {
                                    setTimeout(() => {
                                        navigate("/booking")
                                    }, 0);
                                }} size={24} />
                        </div>
                        <div className=" text-xl font-semibold cursor-pointer">
                            {project.projectName}
                        </div>
                    </div>
                    <div className='flex items-center gap-x-3'>
                        {user?.role === "admin" && (
                            <div onClick={() => navigate(`/manageaccess/${id}`)} className='flex'>
                                <img src={user?.imageUrl} className='h-[28px] z-30 w-[28px] rounded-full' alt="" />
                                <img src={user?.imageUrl !== filteredUsers[0]?.imageUrl ? filteredUsers[0]?.imageUrl : filteredUsers[1]?.imageUrl} className='h-[28px] z-20 ml-[-9px] w-[28px] rounded-full' alt="" />
                                {filteredUsers.length > 2 && (
                                    <div className='h-[28px] text-white z-10 flex justify-center items-center ml-[-9px] w-[28px] rounded-full bg-[#003C9A] text-[14px]'>+{filteredUsers.length - 2}</div>
                                )}
                            </div>
                        )}
                        <div onClick={() => navigate(`/project/${id}`)}>{notWingWiseSvg}</div>
                    </div>
                </div>
            </div>
            <div className="h-[92%] py-3 overflow-y-auto px-3">
                <div className="row p-0 g-3">
                    {wings?.map((wing, index) => (
                        <div onClick={() => navigate(`/wingprojectlist/${wing === "Shops" ? "Shops" : `${wing}-wing`}/${id}`)} className='col-4' key={index}>
                            <div className='bg-[#5A72B5] rounded-[8px] h-[116px] flex flex-col justify-center items-center w-full'>
                                <div className='text-white font-semibold text-[24px]'>{wings[0] === "properties" ? "Prop." : wing.includes("-") ? "Plots" : (id === "6646de08a48a8ab76a8a9161" && wing === "Shops") ? "R house" :wing}</div>
                            </div>
                            <div className='text-center mt-1 font-normal'>{id === "6646de08a48a8ab76a8a9161" && wing === "Shops" ? "Row house" :wing}{(wing !== "Shops" && wing.length < 2) && "-wing"}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WingFlat;

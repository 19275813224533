import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import ProjectComp from "../components/customUi/project-comp";
import AddProjectDialog from "../components/customUi/add-project-dialog";
import { useDispatch, useSelector } from "react-redux";
import { getProjectByTeamId } from "../redux/actions/projectAction";

function BookingPage({ setisLoading }) {
  const navigate = useNavigate();
  const User = useSelector((state) => state.userReducer.user);
  const projects = useSelector((state) => state.projectReducer.projects);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [booking, setBooking] = useState(User?.booking?.split(","));

  useEffect(() => {
    setBooking(User?.booking?.split(","));
  }, [User]);

  useEffect(() => {
    dispatch(getProjectByTeamId(User?.teamId, setisLoading));
  }, [User?.teamId, dispatch, setisLoading]);

  return (
    <div className="h-[100vh]">
      <div className={`${User?.role === "admin" ? "h-[14vh]" : "h-[8vh]"} flex flex-col`}>
        <div className=" flex px-3 py-3 justify-start gap-x-3 items-centerh-[8%]">
          <div className="flex rounded-full justify-center pt-[2px]">
            <BiArrowBack
              onClick={() => {
                setTimeout(() => {
                  navigate("/workspace");
                }, 0);
              }}
              size={24}
            />
          </div>
          <div className=" text-xl font-semibold cursor-pointer">Booking</div>
        </div>
        {User?.role === "admin" && (
          <div className="h-[90%]">
            <div className="flex justify-between items-center h-full">
              <div className={`flex ${pathname === "/booking" ? 'border-b-2 border-[#6750A4]' : 'border-b border-[#e7eoec]'} justify-center items-center w-full h-full`}>Projects</div>
              <div onClick={() => navigate("/analytics")} className={`flex justify-center items-center w-full h-full border-b border-[#e7eoec]`}>Analytics</div>
            </div>
          </div>
        )}
      </div>
      <div className={`px-3 ${User?.role === "admin" ? "h-[86%]" : "h-[92%]"} overflow-y-auto`}>
        {projects.length <= 0 && (
          <div className="h-[60vh] w-full justify-center items-center">
            <div className="h-full px-5 flex flex-col justify-center items-center text-center">
              <div className=" text-[28px] font-semibold">
                No Projects at the moment!
              </div>
              <p className="text-[18px]">
                click + icon below to <br /> add projects.
              </p>
            </div>
          </div>
        )}

        <div className="row p-0 gx-3 mt-3">
          {booking?.includes("all")
            ? projects.map((project, index) => (
              <div key={index} className="col-4">
                <ProjectComp
                  teamId={User?.teamId}
                  id={project._id}
                  name={project.projectName}
                  logo={project.imageUrl}
                />
              </div>
            ))
            : projects
              .filter((project) => booking.includes(project._id))
              .map((filteredProject, index) => (
                <div key={index} className="col-4">
                  <ProjectComp
                    teamId={User?.teamId}
                    id={filteredProject._id}
                    name={filteredProject.projectName}
                    logo={filteredProject.imageUrl}
                  />
                </div>
              ))}
        </div>
        {User.role !== "sitemanager" && (
          <div className={`${""} fixed bottom-40 lg:bottom-36 right-20 lg:right-[42.5%]`}>
            <AddProjectDialog setisLoading={setisLoading} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingPage;
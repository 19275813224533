import { GETPAYMENT } from "../types/types"

const defaultState = {
    payment: [],
}

export const paymentReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GETPAYMENT:
            return {
                ...state, payment: action.data,
            }
        default:
            return {
                ...state
            }
    }
}
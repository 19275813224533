import React, { useEffect } from "react";
import { HOC } from "../components/HOC/HOC";
import AddTeammates from "../components/customUi/add-teammates";
import TeammatesComp from "../components/customUi/teammates-comp";
import { useDispatch, useSelector } from "react-redux";
import { getTeam } from "../redux/actions/teamAction";
import { getAllUserFromTeam } from "../redux/actions/userAction";

function TeamPage({ isAdmin }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const team = useSelector((state) => state.userReducer.teamUser);

  useEffect(() => {
    dispatch(getAllUserFromTeam(user?.teamId));
  }, [user, dispatch]);

  return (
    <>
      {
        user.role === "admin" && (
          <div className="h-[100%]">
            <div className="px-3 h-[8%] flex justify-between items-center border-b border-[#e7eoec]">
              <div className=" text-2xl font-medium">Team</div>
            </div>
            <div className="h-[92%] overflow-y-auto">
              {isAdmin && <AddTeammates color={"bg-[#0066FA]"} />}
              <div className=" border-b border-[#e7eoec] mt-2"></div>
              <div className=" px-3 my-2">
                <div className="my-3 font-normal">
                  Admin - {team.filter((member) => member.role === "admin").length}
                </div>
                <div className=" overflow-hidden" style={{ borderRadius: "8px" }}>
                  {team.map((member, index) => {
                    return (
                      member.role === "admin" && (
                        <TeammatesComp
                          key={index}
                          id={member._id}
                          userId={member.userId}
                          imageUrl={member.imageUrl}
                          name={member.userName}
                          role={member.role}
                        />
                      )
                    );
                  })}
                </div>
                <div className="my-3 font-normal">
                  Office - {team.filter((member) => member.role === "office").length}
                </div>
                <div className=" overflow-hidden" style={{ borderRadius: "8px" }}>
                  {team.map((member, index) => {
                    return (
                      member.role === "office" && (
                        <TeammatesComp
                          key={index}
                          id={member._id}
                          userId={member.userId}
                          imageUrl={member.imageUrl}
                          name={member.userName}
                          role={member.role}
                        />
                      )
                    );
                  })}
                </div>
                <div className=" my-3 font-normal">
                  Site manager -{" "}
                  {team.filter((member) => member.role === "sitemanager").length}
                </div>
                <div className=" overflow-hidden" style={{ borderRadius: "8px" }}>
                  {team.map((member, index) => {
                    return (
                      member.role === "sitemanager" && (
                        <TeammatesComp
                          key={index}
                          id={member._id}
                          userId={member.userId}
                          imageUrl={member.imageUrl}
                          name={member.userName}
                          role={member.role}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default HOC(TeamPage);

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../ui/dialog";
import { Check, Plus } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { MdFormatListNumbered } from "react-icons/md";
import { MdSortByAlpha } from "react-icons/md";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { addFlat } from "../../redux/actions/flatAction";

function AddPlotDialog({ id, setisLoading, type }) {
  useEffect(() => {
    if (type === "Shops") {
      setIsNumberSeries(true);
    }

    if (id === "65fd3d77656bc6f747437aff") {
      setIsStr(true);
    }
  }, [type, id])
  const dispatch = useDispatch();
  const [char, setChar] = useState(type === undefined ? "A" : type.split("-")[0]);
  let [num, setNum] = useState("101");
  const [isStr, setIsStr] = useState(false);
  const user = useSelector((state) => state.userReducer.user);
  let [flat, setFlat] = useState({
    bhkSize: "none",
    squareFeet: "",
    projectId: id,
    series: "",
    customerName: "",
    mobileNumber: "",
    address: "",
    teamId: "",
    createdBy: "",
  });
  const [isNumberSeries, setIsNumberSeries] = useState(false);

  const alphaSeries = ["A", "B", "C", "D", "E", "F"];
  const bhk = ["1", "2", "3", "4", "5", "none"]

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "num") {
      num = value;
      setNum(num);
    }
    if (isNumberSeries) {
      flat = { ...flat, [name]: value };
      flat.series = `${num}`
    } else {
      flat = { ...flat, [name]: value };
      flat.series = `${char}-${num}`
    }
    setFlat({ ...flat });
  }

  const handleSelectChange = (name, value) => {
    if (!isNumberSeries) {
      if (name === "char") {
        setChar(value);
      } else {
        flat = { ...flat, [name]: value };
        flat.series = `${char}-${num}`
        setFlat({ ...flat });
      }
    } else {
      flat = { ...flat, [name]: value };
      flat.series = `${num}`
      setFlat({ ...flat });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    flat = { ...flat, teamId: user.teamId, createdBy: user.userName }
    setFlat({ ...flat });
    dispatch(addFlat(flat, id, setisLoading));
  }

  return (
    <div>
      <Dialog>
        <DialogTrigger className="">
          <button
            className="h-[62px] w-[62px] flex justify-center
             items-center plus absolute active:scale-[0.9] transition-all"
            style={{ borderRadius: "16px" }}
          >
            <Plus size={34} color="#fff" />
          </button>
        </DialogTrigger>
        <DialogContent
          className=" top-60 w-[95vw]"
          style={{ borderRadius: "10px" }}
        >
          <div>
            <div className="mb-2">Plot/flat No.*</div>
            <div className="flex flex-wrap gap-y-2 mb-3 text-xs justify-start items-center gap-x-2">
              <div
                onClick={() => setIsNumberSeries(!isNumberSeries)}
                className={`${isNumberSeries && "sc"
                  } flex justify-center items-center whitespace-nowrap cursor-default gap-x-2 border border-zinc-100 px-2 py-1`}
                style={{ borderRadius: "8px" }}
              >
                <MdFormatListNumbered size={18} /> {isStr ? "String Series" : "Number Series"}
                {isNumberSeries && <Check size={15} />}
              </div>
              <div
                onClick={() => setIsNumberSeries(!isNumberSeries)}
                className={`${!isNumberSeries && "sc"
                  } flex justify-center items-center whitespace-nowrap cursor-default gap-x-2 border border-zinc-100 px-2 py-1`}
                style={{ borderRadius: "8px" }}
              >
                <MdSortByAlpha size={18} /> Alpha Series{" "}
                {!isNumberSeries && <Check size={15} />}
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className=" flex gap-x-3 w-full">
                {!isNumberSeries && (
                  <>
                    <div className={`w-[25%]`}>
                      <Select
                        defaultValue={type === undefined ? "A" : type.split("-")[0]}
                        onValueChange={(value) => handleSelectChange("char", value)}
                        disabled={type !== undefined}
                      >
                        <SelectTrigger
                          className=" sc px-[16px] h-[50px] shadow-none border-0"
                          style={{ borderRadius: "5px" }}
                        >
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent
                          className=""
                          style={{ borderRadius: "5px" }}
                        >
                          {alphaSeries.map((alpha, index) => (
                            <SelectItem key={index} className="select" value={alpha}>
                              {alpha}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </>
                )}
                <div className={`${isNumberSeries ? "w-full" : "w-[70%]"}`}>
                  <input
                    type={isStr ? "text" : "number"}
                    className="w-full sc px-3 outline-none h-[50px]"
                    placeholder={!isStr ? "101" : "Add name"}
                    style={{ borderRadius: "5px" }}
                    name="num"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex gap-x-3 w-full mt-2">
                <div className="w-[70%]">
                  <label className="mb-1">Square feet*</label>
                  <input
                    type="number"
                    className="w-full sc px-3 outline-none h-[50px]"
                    placeholder="4500"
                    style={{ borderRadius: "5px" }}
                    name="squareFeet"
                    onChange={handleChange}
                    step={"any"}
                  />
                </div>
                <div className="w-[28%]">
                  <label className="mb-1">BHK*</label>
                  <Select
                    defaultValue="none"
                    onValueChange={(value) => handleSelectChange("bhkSize", value)}
                  >
                    <SelectTrigger
                      className=" sc px-[16px] h-[50px] shadow-none border-0"
                      style={{ borderRadius: "5px" }}
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="" style={{ borderRadius: "5px" }}>
                      {bhk.map((bhk, index) => (
                        <SelectItem key={index} className="select" value={bhk}>
                          {bhk}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <DialogClose className="w-full">
                <Button
                  type="submit"
                  variant={"primary"}
                  className="flex active:bg-opacity-70 gap-x-2 mt-3 text-[18px] h-[50px] py-[24px] text-white justify-center items-center plus w-full font-[500]"
                  style={{ borderRadius: "8px" }}
                >
                  Create
                </Button>
              </DialogClose>
              <DialogClose className="w-full">
                <Button
                  variant="primary"
                  type="button"
                  className=" border-[1px] mt-3 discard h-[48px] ps-[16px] pe-[24px] w-full flex gap-x-2 text-[18px] font-[500]"
                  style={{ borderRadius: "8px" }}
                >
                  Discard
                </Button>
              </DialogClose>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddPlotDialog;

import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import {
  GET_TASK_BY_ID,
  GET_TASK_BY_TEAMID,
  GET_TASK_BY_USERNAME,
} from "../types/types";
import toast from "react-hot-toast";

export const getTaskByUserName = (
  userName,
  teamId,
  setisLoading = () => {}
) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/task/getTaskByUserName/${userName}/${teamId}`)
      .then((res) => {
        dispatch({ type: GET_TASK_BY_USERNAME, task: res.data });
        setisLoading(false);
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const addTask = (
  task,
  userName,
  teamId,
  setisLoading = () => {},
  email
) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl}/api/task`, task)
      .then((res) => {
        toast.success("Task created successfully");
        dispatch(getTaskByUserName(email, teamId));
        dispatch(getTaskByTeamId(teamId));
      })
      .catch((err) => {
        toast.error("Invalid credentials");
      });
  };
};

export const updateTask = (id, userName, teamId, task, setisLoading) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/task/${id}`, { task })
      .then((res) => {
        dispatch(getTaskByUserName(userName, teamId, setisLoading));
        dispatch(getTaskById(id));
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const getTaskByTeamId = (teamId, setisLoading = () => {}) => {
  setisLoading(true);
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/task/getTaskByTeamId/${teamId}`)
      .then((res) => {
        dispatch({ type: GET_TASK_BY_TEAMID, assignTask: res.data });
        setisLoading(false);
      })
      .catch(() => {
        // handle Error
      });
  };
};

export const deleteTask = (id, teamId, setisLoading, email) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl}/api/task/${id}`)
      .then((res) => {
        dispatch(getTaskByTeamId(teamId, setisLoading));
        dispatch(getTaskByUserName(email, teamId));
        toast.success("Task deleted");
      })
      .catch(() => {
        toast.success("Something went wrong");
        // handle Error
      });
  };
};

export const getTaskById = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl}/api/task/${id}`)
      .then((res) => {
        dispatch({ type: GET_TASK_BY_ID, task: res.data });
      })
      .catch(() => {
        // handle Error
      });
  };
};

import axios from "axios";
import { baseUrl } from "../../lib/base-url";
import { GETPAYMENT } from "../types/types";
import toast from "react-hot-toast";

export const getPayment = (flatId) => {
    return (dispatch) => {
        axios.get(`${baseUrl}/api/payment/${flatId}`)
        .then((res) => {
            dispatch({ type: GETPAYMENT, data: res.data });
        })
        .catch((err) => {});
    }
}

export const addPayment = (payment, flatId) => {
    return (dispatch) => {
        axios.post(`${baseUrl}/api/payment`, payment)
        .then((res) => {
            dispatch(getPayment(flatId));
            toast.success("Payment added");
        })
        .catch((err) => {});
    }
}

export const editPayment = (id, payment, flatId) => {
    return (dispatch) => {
        axios.put(`${baseUrl}/api/payment/${id}`, payment)
        .then((res) => {
            dispatch(getPayment(flatId));
            toast.success("Payment completed");
        })
        .catch((err) => {});
    }
}
import { GETRENT } from "../types/types"

const defaultState = {
    rent: [],
}

export const rentReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GETRENT:
            return {
                ...state, rent: action.data
            }
            default:
            return {
                ...state
            }
    }
}
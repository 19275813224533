import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDSsYMwcipGzE-6BJCt31YdPu204GGRCx8",
  authDomain: "sitenote-da2b4.firebaseapp.com",
  projectId: "sitenote-da2b4",
  storageBucket: "sitenote-da2b4.appspot.com",
  messagingSenderId: "951044006867",
  appId: "1:951044006867:web:82767e74b37c9a17df0526",
  measurementId: "G-XVWB6S8YWH",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getTokenPermission = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BJ-9iIy4TwVKjkIYTyjtqSN4yQJuSBtdMKg1lA6a69urxWvxg3epODPAkDKGGtW___fU-LVcQ014xR51QPHbJ5M",
    });
    
    if (currentToken) {
      return currentToken;
    } else {
      // console.log(
      //   "No registration token available. Request permission to generate one."
      // );
      // handle Error
    }
  } catch (err) {
    // console.log("An error occurred while retrieving token. " + err.message);
    // handle Error
  }
};

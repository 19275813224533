import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LoaderComp({
  isLoading,
}) {

  if(!isLoading) return null;

  return (
    <div className=' fixed top-0 z-50 w-[100vw] flex justify-center items-center'>
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    </div>
  )
}

export default LoaderComp;

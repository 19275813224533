import React from "react";
import { FcGoogle } from "react-icons/fc";

function SigninPage() {
  return (
    <div className=" absolute max-h-[100vh] px-3 w-full flex gap-y-10 flex-col top-[76px] items-center">
      <div className=" font-[600]" style={{fontSize: "48px"}}>Sign in</div>
      <div className=" bg-[#0066FA] font-medium text-xl text-white flex gap-x-3 justify-center items-center lg:w-[350px] w-full h-[60px]"
       style={{borderRadius: "8px"}}
      >
        <div>
          <FcGoogle size={26}/>
        </div>
        <div className="font-[600]">Continue with Google</div>
      </div>
    </div>
  );
}

export default SigninPage;

import { getTokenPermission } from "../firebase";

export const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if(permission === "granted") {
        const token = await getTokenPermission();
        return token;
    } else {
        alert("You denied for the notification");
    }
}